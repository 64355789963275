import axios from "axios";
import { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { useLocation } from "react-router-dom";

const CartCheckout = ({ cart }) => {
  const [myCart, setMyCart] = useState(cart);

  return (
    <header className="bg-white pt-40 md:pt-52 lg:pt-60 py-32">
      <div className="w-[95%] md:w-[80%] m-auto">
        <h1 className="text-secondary font-bold mb-10 font-serif text-3xl border-b-4 border-secondary pb-2 text-center">
          Checkout Cart
        </h1>
        {myCart &&
          myCart.map((product, index) => (
            <div
              key={index}
              className="flex flex-col md:flex-row bg-white rounded-xl shadow-lg mb-10 border border-gray-200 overflow-hidden"
            >
              {/* Product Image */}
              <div className="w-full md:w-[50%] h-[350px] md:h-auto">
                <img
                  className="w-full h-full object-cover transform hover:scale-105 transition duration-300 ease-in-out"
                  src={`https://back.onekoocoffee.com/images/${product.image_url}`}
                  alt="Product IMG"
                />
              </div>

              {/* Product Details */}
              <div className="w-full md:w-[50%] p-6 bg-white flex flex-col justify-between">
                <div>
                  <h2 className="text-left text-2xl font-bold text-secondary mb-4">
                    {product.name}
                  </h2>
                  <p className="text-lg text-gray-700 font-serif mb-4">
                    {product.description}
                  </p>
                </div>

                <div>
                  <p className="text-lg font-semibold text-secondary mb-2">
                    Price:
                  </p>
                  <p className="text-3xl font-bold text-[#cd804a] mb-6">
                    ${product.price}
                  </p>
                </div>
              </div>
            </div>
          ))}
      </div>
    </header>
  );
};

export default CartCheckout;
