import React from "react";

const CoffeeShopBranches = () => {
  const branches = [
    {
      location: "Global Branch",
      address: "Located at Mikwer Plaza",
     },
    {
      location: "Bole Branch",
      address: "Located at ADB building",
    },
    {
      location: "Airport Branch",
      address: "Located at Addis Ababa Airport",
     },
    {
      location: "Lideta Branch",
      address: "Located at Awash Bank building",
         },
  ];

  return (
    <section className="flex grow gap-5 mt-2 text-white max-md:mt-10">
      <div className="flex flex-col grow shrink-0 self-start basis-0 w-fit mx-10">
        <h2 className="text-2xl mb-3">Coffee Shop Branches</h2>
        {branches.map((branch, index) => (
          <React.Fragment key={index}>
            <div className="flex gap-5 justify-start mt-4 ml-0 max-w-full text-sm whitespace-nowrap w-[289px] max-md:ml-2.5">
              <div className="w-1/2">{branch.location}</div>
              <div className="w-1/2">{branch.address}</div>
            </div>
            {index < branches.length - 1 && (
              <div className="shrink-0 mt-6 bg-white border border-black border-solid h-[3px]" />
            )}
          </React.Fragment>
        ))}
      </div>
    </section>
  );
};

export default CoffeeShopBranches;
