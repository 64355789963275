import React from "react";
import SectionTitle from "./SectionTitle";
import SectionSubtitle from "./SectionSubtitle";
import TextBlock from "./TextBlock.jsx";
import ImageWithText from "./ImageWithText";
import qualityImage from "../../../assets/images/historyEstablishmentIcons/quality.png"
import innovationImage from "../../../assets/images/historyEstablishmentIcons/innovation.png"
import missionImage from "../../../assets/images/historyEstablishmentIcons/mission.png"
import visionImage from "../../../assets/images/historyEstablishmentIcons/vision.png"
import communityImage from "../../../assets/images/historyEstablishmentIcons/community.png"
import sustainabilityImage from "../../../assets/images/historyEstablishmentIcons/sustainability.png"
import historyImage from "../../../assets/images/historyEstablishmentIcons/history and establishment.png"
const HistoryEstablishment = () => {
  const imageTextBlocks = [
    {
      imageSrc:missionImage,
      title: "Mission",
      content:
        "To provide the finest quality coffee while fostering sustainable development and enhancing the lives of coffee farmers and communities"  },

    {
      imageSrc:visionImage,
      title: "Vision",
      content:
        "To be a global leader in the coffee industry, renowned for our ethical sourcing, superior coffee products, and positive social impact"},    
    {
      imageSrc:qualityImage,
      title: "Sustainable Sourcing and Quality Production",
      content:
        "Commitment to delivering the highest quality coffee through meticulous sourcing and roasting processes." },
       {
      imageSrc:communityImage,
      title: "Brand Identity and Storytelling",
      content:
        "Empowering local communities through job creation, fair trade practices, and community development initiatives." },
    {
      imageSrc:sustainabilityImage,
      title: "Strategic Insights and Growth",
      content:
        "Implemented market intelligence to stay informed about consumer preferences and competitor activities. Developed strategies to capitalize on opportunities and address market challenges effectively.",
    },
    {
      imageSrc:innovationImage,
      title: "Innovative Marketing and Engagement",
      content:
        "Continuously evolving our offerings and operations to stay ahead in the coffee industry.Continuously evolving our offerings and operations to stay ahead in the coffee industry."    },
  ];

  return (
    <main className="flex overflow-hidden flex-col items-center px-20 h-auto pt-40 pb-14 bg-secondary max-md:px-5">
      <SectionTitle text="History and Establishment" />
      <SectionSubtitle text="From Coffee Roots to Global Cup" />
      <section className="flex flex-wrap gap-5 justify-between mt-16 w-full text-xl font-bold text-white max-w-[1025px] max-md:mt-10 max-md:max-w-full">
        <h3>The Heart of Coffee</h3>
        <h3>A New Dawn for Farmers</h3>
      </section>
      <section className="self-stretch mt-12 max-md:mt-10 max-md:max-w-full">
        <div className="flex gap-5 max-md:flex-col">
          <TextBlock
            text="The journey of coffee begins in Oromia, Ethiopia—the birthplace of this cherished beverage. For the Oromo people, coffee is more than a drink; it's integral to trade, spiritual life, and community cohesion. Despite its cultural significance, Oromia faces challenges such as low market prices and climate change, which impact the livelihoods of smallholder farmers who cultivate 65% of Ethiopia's coffee."
            className="w-[41%] max-md:ml-0 max-md:w-full"
          />
          <div className="flex flex-col ml-5 w-[18%] max-md:ml-0 max-md:w-full">
            <img
              loading="lazy"
              src={historyImage }
              alt="Coffee cultivation in Oromia"
              className="object-cover grow shrink-0 max-w-full rounded-full aspect-square w-[195px] max-md:mt-10"

            />
          </div>
          <TextBlock
            text="To address these challenges, the Oromia Coffee Farmers' Cooperative Union (OCFCU) was founded with a mission to support farmers through social protection, quality assurance, and sustainable practices. Since its establishment on January 30, 2021, Onekoo Coffee has embraced this mission, offering traceable, organic Arabica coffee directly from coffee's origins."
            className="w-[41%] max-md:ml-0 max-md:w-full"
          />
        </div>
      </section>
      {imageTextBlocks.map((block, index) => (
        <ImageWithText
          key={index}
          imageSrc={block.imageSrc}
          title={block.title}
          content={block.content}
        />
      ))}
      <h3 className="self-center mt-24 text-xl font-bold text-center text-white max-md:mt-10">
        Join Us in Our Journey
      </h3>
      <p className="mt-12 ml-9 text-xl text-white text-start md:text-center lg:text-center max-md:mt-10 max-md:max-w-full">
        Every cup of Onekoo Coffee embodies our commitment to quality,
        sustainability, and community. Experience the rich legacy of Ethiopian
        coffee and support the farmers who make it possible. Enjoy a product
        that represents both exceptional taste and a dedication to positive
        impact.
      </p>
    </main>
  );
};

export default HistoryEstablishment;
