import React from "react";
import { FaHome, FaPhone, FaPrint } from "react-icons/fa";
import { MdEmail } from "react-icons/md";

const ContactInfo = () => {
  const contactDetails = [
    {
      icon: <FaHome />,
      text: "Gelan Oromia,  Ethiopia",
      alt: "Location icon",
    },
    {
      icon: <FaPhone />,
      text: "+251 91-234-5678",
      alt: "Phone icon",
    },
    {
      icon: <FaPrint />,
      text: "+251 91-234-5678",
      alt: "Alternative phone icon",
    },
    {
      icon: <MdEmail />,
      text: "info@onekoocoffee.com",
      alt: "Email icon",
    },
  ];

  return (
    <section className="flex gap-9 px-5 text-orange-200 max-md:mt-10 self-center">
      <div className="flex flex-col grow shrink-0 items-start self-start mt-2 basis-0 w-fit">
        <h2 className="self-center text-2xl mb-3 max-md:mb-5">
          Contact us here!
        </h2>
        {contactDetails.map((detail, index) => (
          <div
            key={index}
            className={`flex gap-5 justify-start ${
              index > 0 ? "mt-2" : ""
            } max-w-full text-sm w-[200px]`}
          >
            <div className="shrink-0 w-10 h-10 flex items-center text-start justify-center">
              {detail.icon}
            </div>
            <div className="my-auto self-start text-sm">{detail.text}</div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default ContactInfo;
