import React, { useState } from "react";
import emailjs from "emailjs-com";

const ContactForm = () => {
  // State to store form inputs
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    coverLetter: "",
    position: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleSubmit = (event) => {
    event.preventDefault();

    emailjs
      .send(
        "service_jl4pf52", // Replace with your service ID
        "template_xlc6tek", // Replace with your template ID
        formData,
        "WB5CJg8m4i25_iv7Y" // Replace with your user ID
      )
      .then(
        (result) => {
          alert("Message sent successfully!");
        },
        (error) => {
          alert("Failed to send message. Please try again later.");
        }
      );
  };

  return (
    <div className="my-4 px-4 mx-auto lg:px-20">
      <form
        onSubmit={handleSubmit}
        className="w-full bg-primary p-8 my-4 md:px-12 lg:w-9/12 lg:pl-20 lg:pr-40 mr-auto rounded-2xl shadow-2xl"
      >
        <div id="vacancy" className="flex">
          <h1 className="font-bold text-xl text-white">Vacancy</h1>
        </div>
        <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
          <input
            name="firstName"
            className="w-full bg-white text-bg-white mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline text-PrimaryGreen"
            type="text"
            value={formData.firstName}
            onChange={handleChange}
            placeholder="First Name*"
            required
          />
          <input
            name="lastName"
            className="w-full bg-white text-bg-white mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline text-PrimaryGreen"
            type="text"
            value={formData.lastName}
            onChange={handleChange}
            placeholder="Last Name*"
            required
          />
          <input
            name="email"
            className="w-full bg-white text-bg-white mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline text-PrimaryGreen"
            type="email"
            value={formData.email}
            onChange={handleChange}
            placeholder="Email*"
            required
          />
          <input
            name="phone"
            className="w-full bg-white text-bg-white mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline text-PrimaryGreen"
            type="number"
            value={formData.phone}
            onChange={handleChange}
            placeholder="Phone*"
            required
          />
        </div>
        <div className="my-4">
          <input
            name="position"
            className="w-full bg-white text-bg-white mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline text-PrimaryGreen"
            type="text"
            value={formData.position}
            onChange={handleChange}
            placeholder="Postition applying for"
            required
          />
        </div>
        <div className="my-4">
          <textarea
            name="coverLetter"
            value={formData.coverLetter}
            onChange={handleChange}
            placeholder="Message*"
            className="w-full h-32 bg-white text-bg-white mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline text-PrimaryGreen"
            required
          />
        </div>
        <div className="my-2 w-1/2 lg:w-1/4">
          <button
            type="submit"
            className="text-sm font-bold tracking-wide bg-golden text-white p-3 px-12 rounded-lg w-full 
                    focus:outline-none focus:shadow-outline"
          >
            Send Message
          </button>
        </div>
      </form>
    </div>
  );
};

export default ContactForm;
