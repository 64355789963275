import React, { useEffect, useState } from "react";
import axios from "axios";
import Logo from "../assets/images/onekoo_coffee_logo_psyann_graphics_2024.png";
// import sini from "../assets/images/sini.png";
import sini from "../assets/images/onekoo menu bar sini.png";
import siniHover from "../assets/coffee bean.png";
import { Link, useLocation } from "react-router-dom";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import User from "../assets/images/user.png";
import { CircleUserRound } from "lucide-react";

const NavBar = () => {
  // const navigate = useNavigate();
  const [Active, setActive] = useState("home");
  const [NavWhite, setNavWhite] = useState(false);
  const location = useLocation();
  // const [user, setUser] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isOurStoryHovering, setIsOurStoryHovering] = useState(false);
  const [isFarmToCupHovering, setIsFarmToCupHovering] = useState(false);
  const [isShopHovering, setIsShopHovering] = useState(false);
  const [isMediaHovering, setIsMediaHovering] = useState(false);
  const [isContactHovering, setIsContactHovering] = useState(false);

  axios.defaults.withCredentials = true;
  useEffect(() => {
    axios
      .get("https://back.onekoocoffee.com/api/products/get-all")
      .then((response) => {
        console.log(response.data.products);

        setIsLoggedIn(true);
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
        setIsLoggedIn(false);
      });
  }, []);
  useEffect(() => {
    var currentLocation =
      location.pathname.split("/")[1] === ""
        ? "home"
        : location.pathname.split("/")[1];
    setActive(currentLocation);
    window.addEventListener("scroll", (e) => {
      if (window.pageYOffset >= 452) {
        setNavWhite(true);
      } else {
        setNavWhite(false);
      }
    });
  }, [location.pathname]);
  useEffect(() => {
    let timer;
    if (!isOurStoryHovering) {
      timer = setTimeout(() => {
        setIsOurStoryHovering(false);
      }, 300); // 200ms delay
    } else {
      setIsOurStoryHovering(true);
    }
    return () => clearTimeout(timer);
  }, [isOurStoryHovering]);
  useEffect(() => {
    let timer;
    if (!isFarmToCupHovering) {
      timer = setTimeout(() => {
        setIsFarmToCupHovering(false);
      }, 300); // 200ms delay
    } else {
      setIsFarmToCupHovering(true);
    }
    return () => clearTimeout(timer);
  }, [isFarmToCupHovering]);
  useEffect(() => {
    let timer;
    if (!isShopHovering) {
      timer = setTimeout(() => {
        setIsShopHovering(false);
      }, 300); // 200ms delay
    } else {
      setIsShopHovering(true);
    }
    return () => clearTimeout(timer);
  }, [isShopHovering]);
  useEffect(() => {
    let timer;
    if (!isMediaHovering) {
      timer = setTimeout(() => {
        setIsMediaHovering(false);
      }, 300); // 200ms delay
    } else {
      setIsMediaHovering(true);
    }
    return () => clearTimeout(timer);
  }, [isMediaHovering]);
  useEffect(() => {
    let timer;
    if (!isContactHovering) {
      timer = setTimeout(() => {
        setIsContactHovering(false);
      }, 300); // 200ms delay
    } else {
      setIsContactHovering(true);
    }
    return () => clearTimeout(timer);
  }, [isContactHovering]);

  const handleMouseLeave = (setHoverState) => {
    setTimeout(() => {
      setHoverState(false);
    }, 800); // 200ms delay
  };

  return (
    <>
      <div
        className={`flex fixed mb-32 top-0 mx-auto font-crimson md:px-16 px-[5px] justify-between z-[60] text-white h-[8rem] bg-white pb-2 drop-shadow-[0_5px_5px_rgba(0,0,0,0.25)] w-full`}
      >
        <div className="h-full pl-10">
          <img
            alt="logo"
            className={`${NavWhite ? " h-full" : "h-full"} object-cover`}
            src={Logo}
          />
        </div>
        <div className="md:flex hidden gap-2 justify-start items-start mr-20">
          <Link
            className={`bg-primary rounded-b-lg  border-b-2 border-[#4B2E2E] flex flex-col   ${
              NavWhite
                ? Active === "home"
                  ? " bg-primary h-full  text-white"
                  : " h-full"
                : Active === "home"
                ? "h-[100%] text-primary bg-white"
                : "h-full "
            } flex items-center  h-1/2 p-2.5 w-[5rem] text-[0.8rem] justify-center  text-center`}
            onClick={() => window.scrollTo(0, 0)}
            to={"/home"}
          >
            <img
              src={Active === "home" ? siniHover : sini}
              alt="sini"
              className="h-10 w-10"
            />
            <span className="h-10 w-10 flex items-center justify-center">
              Home
            </span>
          </Link>

          <div
            className="relative"
            onMouseEnter={() => setIsOurStoryHovering(true)}
            onMouseLeave={() => handleMouseLeave(setIsOurStoryHovering)}
          >
            <Link
              className={`bg-primary rounded-b-lg border-b-2 border-[#4B2E2E] flex flex-col  ${
                NavWhite
                  ? Active === "our-story"
                    ? " bg-white h-full  text-primary"
                    : " h-full"
                  : Active === "our-story"
                  ? "h-[120%] text-primary bg-white"
                  : "h-full "
              } flex items-center  h-1/2 p-5 w-[5rem] text-[0.8rem] justify-center  text-center`}
              onClick={() => window.scrollTo(0, 0)}
              to={"/our-story"}
            >
              <img
                src={Active === "our-story" ? siniHover : sini}
                alt="sini"
                className="h-10 w-10"
              />
              <span className="h-10 w-10 flex items-center justify-center">
                Our Story
              </span>
            </Link>
            {isOurStoryHovering && (
              <div className="absolute top-full left-0 w-[11rem] bg-primary/70 shadow-lg rounded-lg mt-2 overflow-hidden">
                <ul className="flex flex-col">
                  <li className="p-2 hover:bg-white">
                    <Link
                      className="text-slate-50"
                      onClick={() => {
                        setIsOurStoryHovering(false);
                      }}
                      to="/our-story#establishment"
                    >
                      Establishment{" "}
                    </Link>
                  </li>
                  <li className="p-2 hover:bg-white">
                    <Link
                      className="text-slate-50"
                      to="/media#awards"
                      onClick={() => {
                        setIsOurStoryHovering(false);
                      }}
                    >
                      Award
                    </Link>
                  </li>
                  <li className="p-2 hover:bg-white">
                    <Link
                      className="text-slate-50"
                      to="/our-story#coffee"
                      onClick={() => {
                        setIsOurStoryHovering(false);
                      }}
                    >
                      Coffee{" "}
                    </Link>
                  </li>
                  <li className="p-2 hover:bg-white">
                    <Link
                      className="text-slate-50"
                      to="/whatwedo#cafe"
                      onClick={() => {
                        setIsOurStoryHovering(false);
                      }}
                    >
                      Café{" "}
                    </Link>
                  </li>
                  <li className="p-2 hover:bg-white">
                    <Link
                      className="text-slate-50"
                      to="/our-story#team"
                      onClick={() => {
                        setIsOurStoryHovering(false);
                      }}
                    >
                      Our Team{" "}
                    </Link>
                  </li>
                </ul>
              </div>
            )}
          </div>

          <div
            className="relative"
            onMouseEnter={() => setIsFarmToCupHovering(true)}
            onMouseLeave={() => handleMouseLeave(setIsFarmToCupHovering)}
          >
            <Link
              className={`bg-primary rounded-b-lg border-b-2 border-[#4B2E2E] flex flex-col  ${
                NavWhite
                  ? Active === "farm-to-cup-main"
                    ? " bg-white h-full  text-primary"
                    : " h-full"
                  : Active === "farm-to-cup-main"
                  ? "h-[120%] text-primary bg-white"
                  : "h-full "
              } flex items-center  h-1/2 p-5 w-[5rem] text-[0.8rem] justify-center  text-center`}
              onClick={() => window.scrollTo(0, 0)}
              to={"/farm-to-cup-main"}
            >
              <img
                src={Active === "farm-to-cup-main" ? siniHover : sini}
                alt="sini"
                className="h-10 w-10"
              />
              <span className="h-10 w-10 flex items-center justify-center">
                Farm to Cup
              </span>
            </Link>
            {isFarmToCupHovering && (
              <div className="absolute top-full left-0 w-[11rem] bg-primary/70 shadow-lg rounded-lg mt-2 overflow-hidden">
                <ul className="flex flex-col">
                  <li className="p-2 hover:bg-white">
                    <Link
                      className="text-slate-50"
                      to="/farm-to-cup-main#supply-chain"
                      onClick={() => {
                        setIsFarmToCupHovering(false);
                      }}
                    >
                      Supply Chain
                    </Link>
                  </li>
                  <li className="p-2 hover:bg-white">
                    <Link
                      className="text-slate-50"
                      to="/farm-to-cup-main#coffee-experience"
                      onClick={() => {
                        setIsFarmToCupHovering(false);
                      }}
                    >
                      Coffee Experience
                    </Link>
                  </li>
                  <li className="p-2 hover:bg-white">
                    <Link
                      className="text-slate-50"
                      to="/farm-to-cup-main#community-initiatives"
                      onClick={() => {
                        setIsFarmToCupHovering(false);
                      }}
                    >
                      Community Initiatives
                    </Link>
                  </li>
                  <li className="p-2 hover:bg-white">
                    <Link
                      className="text-slate-50"
                      to="/farm-to-cup-main#environmental-promise"
                      onClick={() => {
                        setIsFarmToCupHovering(false);
                      }}
                    >
                      Environmental Promise
                    </Link>
                  </li>
                </ul>
              </div>
            )}
          </div>

          <div
            className="relative"
            onMouseEnter={() => setIsShopHovering(true)}
            onMouseLeave={() => handleMouseLeave(setIsShopHovering)}
          >
            <Link
              className={`bg-primary rounded-b-lg border-b-2 border-[#4B2E2E] flex flex-col  ${
                NavWhite
                  ? Active === "shop"
                    ? " bg-white h-full  text-primary"
                    : " h-full"
                  : Active === "shop"
                  ? "h-[120%] text-primary bg-white"
                  : "h-full "
              } flex items-center  h-1/2 p-5 w-[5rem] text-[0.8rem] justify-center  text-center`}
              onClick={() => window.scrollTo(0, 0)}
              to={"/shop"}
            >
              <img
                src={Active === "shop" ? siniHover : sini}
                alt="sini"
                className="h-10 w-10"
              />
              <span className="h-10 w-10 flex items-center justify-center">
                Shop
              </span>
            </Link>
            {isShopHovering && (
              <div className="absolute top-full left-0 w-[11rem] bg-primary/70 shadow-lg rounded-lg mt-2 overflow-hidden">
                <ul className="flex flex-col">
                  <li className="p-2 hover:bg-white">
                    <Link
                      className="text-slate-50"
                      to="/shop#roasted-products"
                      onClick={() => {
                        setIsShopHovering(false);
                      }}
                    >
                      Roasted Coffee
                    </Link>
                  </li>
                  <li className="p-2 hover:bg-white">
                    <Link
                      className="text-slate-50"
                      to="/shop#cafes"
                      onClick={() => {
                        setIsShopHovering(false);
                      }}
                    >
                      Café
                    </Link>
                  </li>
                </ul>
              </div>
            )}
          </div>

          <div
            className="relative"
            onMouseEnter={() => setIsMediaHovering(true)}
            onMouseLeave={() => handleMouseLeave(setIsMediaHovering)}
          >
            <Link
              className={`bg-primary rounded-b-lg border-b-2 border-[#4B2E2E] flex flex-col  ${
                NavWhite
                  ? Active === "media"
                    ? " bg-white h-full  text-primary"
                    : " h-full"
                  : Active === "media"
                  ? "h-[120%] text-primary bg-white"
                  : "h-full "
              } flex items-center  h-1/2 p-5 w-[5rem] text-[0.8rem] justify-center  text-center`}
              onClick={() => window.scrollTo(0, 0)}
              to={"/media"}
            >
              <img
                src={Active === "media" ? siniHover : sini}
                alt="sini"
                className="h-10 w-10"
              />
              <span className="h-10 w-10 flex items-center justify-center">
                Media
              </span>
            </Link>
            {isMediaHovering && (
              <div className="absolute top-full left-0 w-[11rem] bg-primary/70 shadow-lg rounded-lg mt-2 overflow-hidden">
                <ul className="flex flex-col">
                  <li className="p-2 hover:bg-white">
                    <Link
                      className="text-slate-50"
                      to="/media#articles"
                      onClick={() => {
                        setIsMediaHovering(false);
                      }}
                    >
                      News and Articles
                    </Link>
                  </li>
                  <li className="p-2 hover:bg-white">
                    <Link
                      className="text-slate-50"
                      to="/media#blogs"
                      onClick={() => {
                        setIsMediaHovering(false);
                      }}
                    >
                      Blogs
                    </Link>
                  </li>
                  <li className="p-2 hover:bg-white">
                    <Link
                      className="text-slate-50"
                      to="/media#events"
                      onClick={() => {
                        setIsMediaHovering(false);
                      }}
                    >
                      Events
                    </Link>
                  </li>
                  <li className="p-2 hover:bg-white">
                    <Link
                      className="text-slate-50"
                      to="/media#gallery"
                      onClick={() => {
                        setIsMediaHovering(false);
                      }}
                    >
                      Gallery
                    </Link>
                  </li>
                  <li className="p-2 hover:bg-white">
                    <Link
                      className="text-slate-50"
                      to="/contact#vacancy"
                      onClick={() => {
                        setIsMediaHovering(false);
                      }}
                    >
                      Vacancy
                    </Link>
                  </li>
                </ul>
              </div>
            )}
          </div>

          <div
            className="relative"
            // onMouseEnter={() => setIsContactHovering(true)}
            // onMouseLeave={() => handleMouseLeave(setIsContactHovering)}
          >
            <Link
              className={`bg-primary rounded-b-lg border-b-2 border-[#4B2E2E] flex flex-col  ${
                NavWhite
                  ? Active === "contact"
                    ? " bg-white h-full  text-primary"
                    : " h-full"
                  : Active === "contact"
                  ? "h-[120%] text-primary bg-white"
                  : "h-full "
              } flex items-center  h-1/2 p-5 w-[5rem] text-[0.8rem] justify-center  text-center`}
              onClick={() => window.scrollTo(0, 0)}
              to={"/contact"}
            >
              <img
                src={Active === "contact" ? siniHover : sini}
                alt="sini"
                className="h-10 w-10"
              />
              <span className="h-10 w-10 flex items-center justify-center">
                Contact
              </span>
            </Link>
            {isContactHovering && (
              <div className="absolute top-full left-0 w-[11rem] bg-primary/70 shadow-lg rounded-lg mt-2 overflow-hidden">
                <ul className="flex flex-col">
                  <li className="p-2 hover:bg-white">
                    <Link
                      className="text-slate-50"
                      to="/contact#main-office"
                      onClick={() => {
                        setIsContactHovering(false);
                      }}
                    >
                      Main office address
                    </Link>
                  </li>
                  <li className="p-2 hover:bg-white">
                    <Link
                      className="text-slate-50"
                      to="/contact#marketing-department"
                      onClick={() => {
                        setIsContactHovering(false);
                      }}
                    >
                      Marketing department address
                    </Link>
                  </li>
                  <li className="p-2 hover:bg-white">
                    <Link
                      className="text-slate-50"
                      to="/contact#coffee-shop-branch"
                      onClick={() => {
                        setIsContactHovering(false);
                      }}
                    >
                      Coffee shop branch address
                    </Link>
                  </li>
                </ul>
              </div>
            )}
          </div>

          <Link
            className={`bg-primary rounded-b-lg border-b-2 border-[#4B2E2E] flex flex-col  ${
              NavWhite
                ? Active === "account"
                  ? " bg-white h-full  text-primary"
                  : " h-full"
                : Active === "account"
                ? "h-[100%] text-primary bg-white"
                : "h-full "
            } flex items-center  h-1/2 p-2.5 w-[5rem] text-[0.8rem] justify-center  text-center`}
            onClick={() => window.scrollTo(0, 0)}
            to={isLoggedIn ? "/profile" : "/account"}
          >
            {/* <img
              src={User} // Use user.jpg here
              alt="User"
              className="h-10 w-10"
            /> */}
            <CircleUserRound className="w-[70px] mt-2 h-[70px] text-[#094709]" />
            <span className="h-10 w-10 flex items-center justify-center">
              Account
            </span>
          </Link>
        </div>

        <div className="relative group md:hidden flex items-center">
          <div className="cursor-pointer text-primary" onClick={() => {}}>
            <MenuOutlinedIcon />
          </div>
          <div className="absolute right-0 top-[70%] items-center hidden group-hover:flex flex-col gap-1">
            <Link onClick={() => window.scrollTo(0, 0)} to={"/home"}>
              <div
                className={`bg-primary rounded-xl hover:scale-110 ${
                  Active === "home" ? "h-full text-white" : "h-1/2"
                } ${
                  NavWhite ? " h-full" : ""
                } flex items-end p-2.5 w-[5rem] text-[0.8rem] justify-center text-center`}
              >
                Home
              </div>
            </Link>

            {/* Our Story */}
            <div
              className="relative"
              onMouseEnter={() => setIsOurStoryHovering(true)}
              onMouseLeave={() => handleMouseLeave(setIsOurStoryHovering)}
            >
              <Link onClick={() => window.scrollTo(0, 0)} to={"/our-story"}>
                <div
                  className={`bg-primary rounded-xl hover:scale-110 ${
                    Active === "our-story" ? "h-full text-white" : "h-1/2"
                  } ${
                    NavWhite ? " h-full" : ""
                  } flex items-end p-2.5 w-[5rem] text-[0.8rem] justify-center text-center`}
                >
                  Our Story
                </div>
              </Link>
              {isOurStoryHovering && (
                <div className="absolute top-0 left-[-8.3rem] w-[8rem] bg-primary/70 shadow-lg rounded-lg mt-2 overflow-hidden">
                  <ul className="flex flex-col">
                    <li className="p-1 hover:bg-white">
                      <Link
                        className="text-slate-50 text-sm"
                        to="/our-story#establishment"
                        onClick={() => {
                          setIsOurStoryHovering(false);
                        }}
                      >
                        Establishment
                      </Link>
                    </li>
                    <li className="p-1 hover:bg-white">
                      <Link
                        className="text-slate-50 text-sm"
                        to="/media#awards"
                        onClick={() => {
                          setIsOurStoryHovering(false);
                        }}
                      >
                        Award
                      </Link>
                    </li>
                    <li className="p-1 hover:bg-white">
                      <Link
                        className="text-slate-50 text-sm"
                        to="/our-story#coffee"
                        onClick={() => {
                          setIsOurStoryHovering(false);
                        }}
                      >
                        Coffee
                      </Link>
                    </li>
                    <li className="p-1 hover:bg-white">
                      <Link
                        className="text-slate-50 text-sm"
                        to="/whatwedo#cafe"
                        onClick={() => {
                          setIsOurStoryHovering(false);
                        }}
                      >
                        Café
                      </Link>
                    </li>
                    <li className="p-1 hover:bg-white">
                      <Link
                        className="text-slate-50 text-sm"
                        to="/our-story#team"
                        onClick={() => {
                          setIsOurStoryHovering(false);
                        }}
                      >
                        Our Team
                      </Link>
                    </li>
                  </ul>
                </div>
              )}
            </div>

            {/* Farm to Cup */}
            <div
              className="relative"
              onMouseEnter={() => setIsFarmToCupHovering(true)}
              onMouseLeave={() => handleMouseLeave(setIsFarmToCupHovering)}
            >
              <Link
                onClick={() => window.scrollTo(0, 0)}
                to={"/farm-to-cup-main"}
              >
                <div
                  className={`bg-primary rounded-xl hover:scale-110 ${
                    Active === "farm-to-cup-main"
                      ? "h-full text-white"
                      : "h-1/2"
                  } ${
                    NavWhite ? " h-full" : ""
                  } flex items-end p-2.5 w-[5rem] text-[0.8rem] justify-center text-center`}
                >
                  Farm to Cup
                </div>
              </Link>
              {isFarmToCupHovering && (
                <div className="absolute top-0 left-[-8.3rem] w-[8rem] bg-primary/70 shadow-lg rounded-lg mt-2 overflow-hidden">
                  <ul className="flex flex-col">
                    <li className="p-1 hover:bg-white">
                      <Link
                        className="text-slate-50 text-sm"
                        to="/farm-to-cup-main#supply-chain"
                        onClick={() => {
                          setIsFarmToCupHovering(false);
                        }}
                      >
                        Supply Chain
                      </Link>
                    </li>
                    <li className="p-1 hover:bg-white">
                      <Link
                        className="text-slate-50 text-sm"
                        to="/farm-to-cup-main#coffee-experience"
                        onClick={() => {
                          setIsFarmToCupHovering(false);
                        }}
                      >
                        Coffee Experience
                      </Link>
                    </li>
                    <li className="p-1 hover:bg-white">
                      <Link
                        className="text-slate-50 text-sm"
                        to="/farm-to-cup-main#community-initiatives"
                        onClick={() => {
                          setIsFarmToCupHovering(false);
                        }}
                      >
                        Community Initiatives
                      </Link>
                    </li>
                    <li className="p-1 hover:bg-white">
                      <Link
                        className="text-slate-50 text-sm"
                        to="/farm-to-cup-main#environmental-promise"
                        onClick={() => {
                          setIsFarmToCupHovering(false);
                        }}
                      >
                        Environmental Promise
                      </Link>
                    </li>
                  </ul>
                </div>
              )}
            </div>

            {/* Shop */}
            <div
              className="relative"
              onMouseEnter={() => setIsShopHovering(true)}
              onMouseLeave={() => handleMouseLeave(setIsShopHovering)}
            >
              <Link onClick={() => window.scrollTo(0, 0)} to={"/shop"}>
                <div
                  className={`bg-primary rounded-xl hover:scale-110 ${
                    Active === "shop" ? "h-full text-white" : "h-1/2"
                  } ${
                    NavWhite ? " h-full" : ""
                  } flex items-end p-2.5 w-[5rem] text-[0.8rem] justify-center text-center`}
                >
                  Shop
                </div>
              </Link>
              {isShopHovering && (
                <div className="absolute top-0 left-[-8.3rem] w-[8rem] bg-primary/70 shadow-lg rounded-lg mt-2 overflow-hidden">
                  <ul className="flex flex-col">
                    <li className="p-1 hover:bg-white">
                      <Link
                        className="text-slate-50 text-sm"
                        to="/shop#roasted-products"
                        onClick={() => setIsShopHovering(false)}
                      >
                        Roasted Coffee
                      </Link>
                    </li>
                    <li className="p-1 hover:bg-white">
                      <Link
                        className="text-slate-50 text-sm"
                        to="/shop#cafes"
                        onClick={() => setIsShopHovering(false)}
                      >
                        Café
                      </Link>
                    </li>
                  </ul>
                </div>
              )}
            </div>

            {/* Media */}
            <div
              className="relative"
              onMouseEnter={() => setIsMediaHovering(true)}
              onMouseLeave={() => handleMouseLeave(setIsMediaHovering)}
            >
              <Link onClick={() => window.scrollTo(0, 0)} to={"/media"}>
                <div
                  className={`bg-primary rounded-xl hover:scale-110 ${
                    Active === "media" ? "h-full text-white" : "h-1/2"
                  } ${
                    NavWhite ? " h-full" : ""
                  } flex items-end p-2.5 w-[5rem] text-[0.8rem] justify-center text-center`}
                >
                  Media
                </div>
              </Link>
              {isMediaHovering && (
                <div className="absolute top-0 left-[-8.3rem] w-[8rem] bg-primary/70 shadow-lg rounded-lg mt-2 overflow-hidden">
                  <ul className="flex flex-col">
                    <li className="p-1 hover:bg-white">
                      <Link
                        className="text-slate-50 text-sm"
                        to="/media#articles"
                        onClick={() => setIsMediaHovering(false)}
                      >
                        News and Articles
                      </Link>
                    </li>
                    <li className="p-1 hover:bg-white">
                      <Link
                        className="text-slate-50 text-sm"
                        to="/media#blogs"
                        onClick={() => setIsMediaHovering(false)}
                      >
                        Blogs
                      </Link>
                    </li>
                    <li className="p-1 hover:bg-white">
                      <Link
                        className="text-slate-50 text-sm"
                        to="/media#events"
                        onClick={() => setIsMediaHovering(false)}
                      >
                        Events
                      </Link>
                    </li>
                    <li className="p-1 hover:bg-white">
                      <Link
                        className="text-slate-50 text-sm"
                        to="/media#gallery"
                        onClick={() => setIsMediaHovering(false)}
                      >
                        Gallery
                      </Link>
                    </li>
                    <li className="p-1 hover:bg-white">
                      <Link
                        className="text-slate-50 text-sm"
                        to="/contact#vacancy"
                        onClick={() => setIsMediaHovering(false)}
                      >
                        Vacancy
                      </Link>
                    </li>
                  </ul>
                </div>
              )}
            </div>

            {/* Contact */}
            <div
              className="relative"
              // onMouseEnter={() => setIsContactHovering(true)}
              // onMouseLeave={() => handleMouseLeave(setIsContactHovering)}
            >
              <Link onClick={() => window.scrollTo(0, 0)} to={"/contact"}>
                <div
                  className={`bg-primary rounded-xl hover:scale-110 ${
                    Active === "contact" ? "h-full text-white" : "h-1/2"
                  } ${
                    NavWhite ? " h-full" : ""
                  } flex items-end p-2.5 w-[5rem] text-[0.8rem] justify-center text-center`}
                >
                  Contact
                </div>
              </Link>
              {isContactHovering && (
                <div className="absolute top-0 left-[-8.3rem] w-[8rem] bg-primary/70 shadow-lg rounded-lg mt-2 overflow-hidden">
                  <ul className="flex flex-col">
                    <li className="p-1 hover:bg-white">
                      <Link
                        className="text-slate-50 text-sm"
                        to="/contact#main-office"
                        onClick={() => setIsContactHovering(false)}
                      >
                        Main office address
                      </Link>
                    </li>
                    <li className="p-1 hover:bg-white">
                      <Link
                        className="text-slate-50 text-sm"
                        to="/contact#marketing-department"
                        onClick={() => setIsContactHovering(false)}
                      >
                        Marketing department address
                      </Link>
                    </li>
                    <li className="p-1 hover:bg-white">
                      <Link
                        className="text-slate-50 text-sm"
                        to="/contact#coffee-shop-branch"
                        onClick={() => setIsContactHovering(false)}
                      >
                        Coffee shop branch address
                      </Link>
                    </li>
                  </ul>
                </div>
              )}
            </div>

            <Link
              className={`bg-primary rounded-lg flex flex-col ${
                NavWhite
                  ? Active === "account"
                    ? " bg-white h-full  text-primary"
                    : " h-full"
                  : Active === "account"
                  ? "h-[100%] text-primary bg-white"
                  : "h-full "
              } flex items-center h-1/2 p-2.5 w-[5rem] text-[0.8rem] justify-center text-center`}
              onClick={() => window.scrollTo(0, 0)}
              to={isLoggedIn ? "/profile" : "/account"}
            >
              {/* <img src={User} alt="User" className="h-10 w-10 bg" /> */}
              <CircleUserRound className="w-[50px] mt-2 h-[50px] text-[#094709]" />
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default NavBar;
