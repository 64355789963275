// import React, { useState, useEffect } from "react";
// import axios from "axios";
// import { motion } from "framer-motion";
// import { GoFilter } from "react-icons/go";

// function Filter({ products, setProducts, fetchAgain }) {
//   const [searchQuery, setSearchQuery] = useState("");
//   const [selectedLocation, setSelectedLocation] = useState("");
//   const [selectedShop, setSelectedShop] = useState("");
//   const [selectedQuality, setSelectedQuality] = useState("");
//   const [selectedCategory, setSelectedCategory] = useState("");
//   const [priceRange, setPriceRange] = useState({ min: "", max: "" });
//   const [quantityRange, setQuantityRange] = useState({ min: "", max: "" });
//   const [filteredData, setFilteredData] = useState([]);
//   const [shops, setShops] = useState(null);

//   useEffect(() => {
//     axios
//       .get(`https://back.onekoocoffee.com/api/shops/all-shops`)
//       .then((res) => {
//         console.log(res.data);
//         setShops(res.data);
//       })
//       .catch((error) => {
//         console.log(error);
//       });
//   }, []);

//   const handleSearch = () => {
//     console.log("from filter");
//     console.log(
//       searchQuery,
//       selectedLocation,
//       selectedCategory,
//       selectedQuality,
//       priceRange
//     );
//     if (selectedLocation !== "") {
//       setProducts(
//         products.filter((product) => {
//           return product.Location === selectedLocation;
//         })
//       );
//     }
//     if (selectedCategory !== "") {
//       console.log("nahom", "in category");
//       setProducts(
//         products.filter((product) => {
//           return product.category_id === Number(selectedCategory);
//         })
//       );
//     }
//     if (selectedQuality !== "") {
//       setProducts(
//         products.filter((product) => {
//           return product.quality === selectedQuality;
//         })
//       );
//     }
//     if (selectedShop !== "") {
//       setProducts(
//         products.filter((product) => {
//           return product.shop_id === Number(selectedShop);
//         })
//       );
//     }
//     if (searchQuery !== "") {
//       setProducts(
//         products.filter((product) => {
//           return product.name === searchQuery;
//         })
//       );
//     }
//     if (priceRange.min !== "" && priceRange.max !== "") {
//       console.log("nahom");
//       setProducts(
//         products.filter((product) => {
//           console.log(
//             Number(priceRange.min) <= Number(product.price) &&
//               Number(priceRange.max) >= Number(product.price)
//           );
//           return (
//             Number(priceRange.min) <= Number(product.price) &&
//             Number(priceRange.max) >= Number(product.price)
//           );
//         })
//       );
//     }

//     console.log(products);
//   };

//   const handleLocationChange = (e) => {
//     setSelectedLocation(e.target.value);
//   };

//   const handleShopChange = (e) => {
//     setSelectedShop(e.target.value);
//   };

//   const handleQualityChange = (e) => {
//     setSelectedQuality(e.target.value);
//   };

//   const handleCategoryChange = (e) => {
//     setSelectedCategory(e.target.value);
//   };

//   const handlePriceChange = (e) => {
//     const { name, value } = e.target;
//     setPriceRange((prevRange) => ({
//       ...prevRange,
//       [name]: value,
//     }));
//   };

//   const handleQuantityChange = (e) => {
//     const { name, value } = e.target;
//     setQuantityRange((prevRange) => ({
//       ...prevRange,
//       [name]: value,
//     }));
//   };

//   return (
//     <div className="flex flex-row pt-5 pb-16 mt-5 mx-auto w-full h-[100%] md:h-[100%] lg:h-[90rem] bg-secondary rounded-xl p-4">
//       <div className="flex flex-row w-full">
//         {/* Search */}
//         <div className="gap-2 mb-4">
//           <input
//             type="text"
//             placeholder="Search"
//             className="bg-secondary text-white outline-none text-xs md:text-sm px-2 py-1 rounded-full border border-white w-[9rem]"
//             value={searchQuery}
//             onChange={(e) => setSearchQuery(e.target.value)}
//           />
//           <div className="flex justify-between mt-4 items-center">
//             <button
//               onClick={handleSearch}
//               className="material-icons text-white text-xs md:text-sm px-2 border border-white rounded"
//               z
//             >
//               search
//             </button>
//             <button
//               onClick={() => {
//                 setPriceRange({ min: "", max: "" });
//                 setSelectedLocation("");
//                 setSelectedShop("");
//                 setSelectedCategory("");
//                 setSelectedQuality("");
//                 setSearchQuery("");
//                 fetchAgain();
//               }}
//               className="material-icons text-white text-xs md:text-sm px-2 border border-white rounded"
//               z
//             >
//               Clear
//             </button>
//           </div>
//         </div>

//         {/* Filter Header */}
//         <div className="flex items-center gap-2 mb-4">
//           <span className="text-xs md:text-sm text-white">Filter cart</span>
//           <span className="material-icons text-white text-xs md:text-sm">
//             <GoFilter />
//           </span>
//         </div>

//         {/* Sort by Price */}
//         <div className="text-xs md:text-sm text-white mb-2">Sort by price</div>
//         <div className="flex gap-2 items-center mb-4">
//           <div className="flex flex-auto gap-2 items-center py-1 px-2 bg-secondary rounded-lg border border-white">
//             <input
//               type="text"
//               name="min"
//               placeholder="Min"
//               className="bg-secondary text-white rounded-md text-xs md:text-sm px-2 py-1 w-[70px] text-center border border-white"
//               value={priceRange.min}
//               onChange={handlePriceChange}
//             />
//             <div className="text-xs md:text-sm text-white">-</div>
//             <input
//               type="text"
//               name="max"
//               placeholder="Max"
//               className="bg-secondary text-white rounded-md text-xs md:text-sm px-2 py-1 w-[70px] text-center border border-white"
//               value={priceRange.max}
//               onChange={handlePriceChange}
//             />
//           </div>
//         </div>
//         {/* sort by shop */}
//         <div className="text-xs md:text-sm text-white mb-2">Sort by Shop</div>
//         <motion.div
//           className="flex flex-col gap-2"
//           initial={{ opacity: 0 }}
//           animate={{ opacity: 1 }}
//           transition={{ duration: 0.3 }}
//         >
//           <motion.select
//             className="bg-secondary text-white rounded-md outline-none text-xs md:text-sm w-full py-1 px-2 border border-white"
//             value={selectedShop}
//             onChange={handleShopChange}
//           >
//             <option value="" disabled>
//               Select Shop
//             </option>
//             {shops &&
//               shops.map((shop, index) => {
//                 return (
//                   <motion.option
//                     key={index}
//                     value={shop.id}
//                     initial={{ opacity: 0, transform: "translateY(-10px)" }}
//                     animate={{ opacity: 1, transform: "translateY(0)" }}
//                     transition={{ duration: 0.3 }}
//                   >
//                     {shop.name}
//                   </motion.option>
//                 );
//               })}
//           </motion.select>
//         </motion.div>

//         {/* Sort by Location */}
//         <div className="text-xs md:text-sm text-white mb-2">
//           Sort by location
//         </div>
//         <motion.div
//           className="flex flex-col gap-2"
//           initial={{ opacity: 0 }}
//           animate={{ opacity: 1 }}
//           transition={{ duration: 0.3 }}
//         >
//           <motion.select
//             className="bg-secondary text-white rounded-md outline-none text-xs md:text-sm w-full py-1 px-2 border border-white"
//             value={selectedLocation}
//             onChange={handleLocationChange}
//           >
//             <option value="" disabled>
//               Select Location
//             </option>
//             <motion.option
//               value="Addis Ababa"
//               initial={{ opacity: 0, transform: "translateY(-10px)" }}
//               animate={{ opacity: 1, transform: "translateY(0)" }}
//               transition={{ duration: 0.3 }}
//             >
//               Addis Ababa
//             </motion.option>
//             <motion.option
//               value="Adama"
//               initial={{ opacity: 0, transform: "translateY(-10px)" }}
//               animate={{ opacity: 1, transform: "translateY(0)" }}
//               transition={{ duration: 0.3 }}
//             >
//               Adama
//             </motion.option>
//             <motion.option
//               value="Yirgacheffe"
//               initial={{ opacity: 0, transform: "translateY(-10px)" }}
//               animate={{ opacity: 1, transform: "translateY(0)" }}
//               transition={{ duration: 0.3 }}
//             >
//               Yirgacheffe
//             </motion.option>
//           </motion.select>
//         </motion.div>
//         {/* Sort by Quality */}
//         <div className="text-xs md:text-sm text-white my-2">
//           Sort by Quality (in grade)
//         </div>
//         <motion.div
//           className="flex flex-col gap-2"
//           initial={{ opacity: 0, height: 0 }}
//           animate={{ opacity: 1, height: "auto" }}
//           transition={{ duration: 0.3 }}
//         >
//           <motion.select
//             className="bg-secondary text-white rounded-md outline-none text-xs md:text-sm w-full py-1 px-2 border border-white"
//             value={selectedQuality}
//             onChange={handleQualityChange}
//           >
//             <option value="" disabled>
//               Select Quality
//             </option>
//             <motion.option
//               value="A"
//               initial={{ opacity: 0, transform: "translateY(-10px)" }}
//               animate={{ opacity: 1, transform: "translateY(0)" }}
//               transition={{ duration: 0.3 }}
//             >
//               Grade A
//             </motion.option>
//             <motion.option
//               value="B"
//               initial={{ opacity: 0, transform: "translateY(-10px)" }}
//               animate={{ opacity: 1, transform: "translateY(0)" }}
//               transition={{ duration: 0.3 }}
//             >
//               Grade B
//             </motion.option>
//             <motion.option
//               value="C"
//               initial={{ opacity: 0, transform: "translateY(-10px)" }}
//               animate={{ opacity: 1, transform: "translateY(0)" }}
//               transition={{ duration: 0.3 }}
//             >
//               Grade C
//             </motion.option>
//           </motion.select>
//         </motion.div>

//         {/* Sort by Category */}
//         <div className="text-xs md:text-sm text-white my-2">
//           Sort by category
//         </div>
//         <motion.div
//           className="flex flex-col gap-2"
//           initial={{ opacity: 0, height: 0 }}
//           animate={{ opacity: 1, height: "auto" }}
//           transition={{ duration: 0.3 }}
//         >
//           <motion.select
//             className="bg-secondary text-white rounded-md outline-none text-xs md:text-sm w-full py-1 px-2 border border-white"
//             value={selectedCategory}
//             onChange={handleCategoryChange}
//           >
//             <option value="" disabled>
//               Select category
//             </option>
//             <motion.option
//               value="1"
//               initial={{ opacity: 0, transform: "translateY(-10px)" }}
//               animate={{ opacity: 1, transform: "translateY(0)" }}
//               transition={{ duration: 0.3 }}
//             >
//               Coffee
//             </motion.option>
//             <motion.option
//               value="2"
//               initial={{ opacity: 0, transform: "translateY(-10px)" }}
//               animate={{ opacity: 1, transform: "translateY(0)" }}
//               transition={{ duration: 0.3 }}
//             >
//               Cookies
//             </motion.option>
//           </motion.select>
//         </motion.div>

//         {/* Display Filtered Data */}
//         <div className="text-xs md:text-sm text-white mt-4">
//           Showing {products && products.length} results
//         </div>
//         <div className="flex flex-col gap-2 mt-2">
//           {/* Render your filtered data here */}
//           {filteredData.map((item, index) => (
//             <div key={index} className="text-white">
//               {item.name} - {item.price}
//             </div>
//           ))}
//         </div>
//       </div>
//     </div>
//   );
// }

// export default Filter;

import React, { useState, useEffect } from "react";
import axios from "axios";
import { motion } from "framer-motion";
import { GoFilter } from "react-icons/go";

function Filter({ products, setProducts, fetchAgain }) {
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedLocation, setSelectedLocation] = useState("");
  const [selectedShop, setSelectedShop] = useState("");
  const [selectedQuality, setSelectedQuality] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [priceRange, setPriceRange] = useState({ min: "", max: "" });
  const [quantityRange, setQuantityRange] = useState({ min: "", max: "" });
  const [filteredData, setFilteredData] = useState([]);
  const [shops, setShops] = useState(null);

  useEffect(() => {
    axios
      .get(`https://back.onekoocoffee.com/api/shops/all-shops`)
      .then((res) => {
        console.log(res.data);
        setShops(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const handleSearch = () => {
    console.log("from filter");
    console.log(
      searchQuery,
      selectedLocation,
      selectedCategory,
      selectedQuality,
      priceRange
    );
    if (selectedLocation !== "") {
      setProducts(
        products.filter((product) => {
          return product.Location === selectedLocation;
        })
      );
    }
    if (selectedCategory !== "") {
      console.log("nahom", "in category");
      setProducts(
        products.filter((product) => {
          return product.category_id === Number(selectedCategory);
        })
      );
    }
    if (selectedQuality !== "") {
      setProducts(
        products.filter((product) => {
          return product.quality === selectedQuality;
        })
      );
    }
    if (selectedShop !== "") {
      setProducts(
        products.filter((product) => {
          return product.shop_id === Number(selectedShop);
        })
      );
    }
    if (searchQuery !== "") {
      setProducts(
        products.filter((product) => {
          return product.name === searchQuery;
        })
      );
    }
    if (priceRange.min !== "" && priceRange.max !== "") {
      console.log("nahom");
      setProducts(
        products.filter((product) => {
          console.log(
            Number(priceRange.min) <= Number(product.price) &&
              Number(priceRange.max) >= Number(product.price)
          );
          return (
            Number(priceRange.min) <= Number(product.price) &&
            Number(priceRange.max) >= Number(product.price)
          );
        })
      );
    }

    console.log(products);
  };

  const handleLocationChange = (e) => {
    setSelectedLocation(e.target.value);
  };

  const handleShopChange = (e) => {
    setSelectedShop(e.target.value);
  };

  const handleQualityChange = (e) => {
    setSelectedQuality(e.target.value);
  };

  const handleCategoryChange = (e) => {
    setSelectedCategory(e.target.value);
  };

  const handlePriceChange = (e) => {
    const { name, value } = e.target;
    setPriceRange((prevRange) => ({
      ...prevRange,
      [name]: value,
    }));
  };

  const handleQuantityChange = (e) => {
    const { name, value } = e.target;
    setQuantityRange((prevRange) => ({
      ...prevRange,
      [name]: value,
    }));
  };

  return (
    <div className="flex flex-col pt-5 pb-16 mt-5 mx-auto w-full h-[100%] lg:h-[90vh] bg-primary rounded-xl p-4 overflow-x-auto">
      {/* Left Section: Search and Filter Options */}
      <div className="flex flex-col gap-4 w-full lg:w-1/3">
        {/* Search */}
        <div className="flex flex-col gap-2 mb-4">
          <input
            type="text"
            placeholder="Search"
            className="bg-secondary text-white outline-none text-xs md:text-sm px-2 py-1 rounded-full border border-white"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          <div className="flex gap-2 mt-2">
            <button
              onClick={handleSearch}
              className="material-icons text-white text-xs md:text-sm px-2 border border-white rounded"
            >
              search
            </button>
            <button
              onClick={() => {
                setPriceRange({ min: "", max: "" });
                setSelectedLocation("");
                setSelectedShop("");
                setSelectedCategory("");
                setSelectedQuality("");
                setSearchQuery("");
                fetchAgain();
              }}
              className="material-icons text-white text-xs md:text-sm px-2 border border-white rounded"
            >
              Clear
            </button>
          </div>
        </div>

        {/* Filter Options */}
        <div className="flex flex-col gap-4">
          {/* Sort by Price */}
          <div className="flex flex-col">
            <div className="text-xs md:text-sm text-white mb-2">
              Sort by price
            </div>
            <div className="flex gap-2 items-center">
              <input
                type="text"
                name="min"
                placeholder="Min"
                className="bg-secondary text-white rounded-md text-xs md:text-sm px-2 py-1 w-[70px] text-center border border-white"
                value={priceRange.min}
                onChange={handlePriceChange}
              />
              <div className="text-xs md:text-sm text-white">-</div>
              <input
                type="text"
                name="max"
                placeholder="Max"
                className="bg-secondary text-white rounded-md text-xs md:text-sm px-2 py-1 w-[70px] text-center border border-white"
                value={priceRange.max}
                onChange={handlePriceChange}
              />
            </div>
          </div>

          {/* Sort by Shop */}
          <div className="flex flex-col">
            <div className="text-xs md:text-sm text-white mb-2">
              Sort by Shop
            </div>
            <motion.select
              className="bg-secondary text-white rounded-md outline-none text-xs md:text-sm w-full py-1 px-2 border border-white"
              value={selectedShop}
              onChange={handleShopChange}
            >
              <option value="" disabled>
                Select Shop
              </option>
              {shops &&
                shops.map((shop, index) => (
                  <motion.option key={index} value={shop.id}>
                    {shop.name}
                  </motion.option>
                ))}
            </motion.select>
          </div>

          {/* Sort by Location */}
          <div className="flex flex-col">
            <div className="text-xs md:text-sm text-white mb-2">
              Sort by location
            </div>
            <motion.select
              className="bg-secondary text-white rounded-md outline-none text-xs md:text-sm w-full py-1 px-2 border border-white"
              value={selectedLocation}
              onChange={handleLocationChange}
            >
              <option value="" disabled>
                Select Location
              </option>
              <motion.option value="Addis Ababa">Addis Ababa</motion.option>
              <motion.option value="Adama">Adama</motion.option>
              <motion.option value="Yirgacheffe">Yirgacheffe</motion.option>
            </motion.select>
          </div>

          {/* Sort by Quality */}
          <div className="flex flex-col">
            <div className="text-xs md:text-sm text-white mb-2">
              Sort by Quality (in grade)
            </div>
            <motion.select
              className="bg-secondary text-white rounded-md outline-none text-xs md:text-sm w-full py-1 px-2 border border-white"
              value={selectedQuality}
              onChange={handleQualityChange}
            >
              <option value="" disabled>
                Select Quality
              </option>
              <motion.option value="A">Grade A</motion.option>
              <motion.option value="B">Grade B</motion.option>
              <motion.option value="C">Grade C</motion.option>
            </motion.select>
          </div>

          {/* Sort by Category */}
          <div className="flex flex-col">
            <div className="text-xs md:text-sm text-white mb-2">
              Sort by category
            </div>
            <motion.select
              className="bg-secondary text-white rounded-md outline-none text-xs md:text-sm w-full py-1 px-2 border border-white"
              value={selectedCategory}
              onChange={handleCategoryChange}
            >
              <option value="" disabled>
                Select category
              </option>
              <motion.option value="1">Coffee</motion.option>
              <motion.option value="2">Cookies</motion.option>
            </motion.select>
          </div>
        </div>
      </div>

      {/* Right Section: Display Filtered Data */}
      <div className="flex flex-col flex-grow gap-2 ml-4 overflow-y-auto">
        <div className="text-xs md:text-sm text-white mb-2">
          Showing {products && products.length} results
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {filteredData.length > 0 ? (
            filteredData.map((product) => (
              <div
                key={product.id}
                className="flex flex-col bg-secondary rounded-md p-4"
              >
                <div className="text-white text-sm mb-2">{product.name}</div>
                <div className="text-xs text-gray-400 mb-1">
                  Location: {product.Location}
                </div>
                <div className="text-xs text-gray-400 mb-1">
                  Quality: {product.quality}
                </div>
                <div className="text-xs text-gray-400 mb-1">
                  Price: ${product.price}
                </div>
              </div>
            ))
          ) : (
            <div className="text-white text-sm">No results found</div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Filter;
