import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

function ArticleAndNews() {
  const [articles, setArticles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentIndex, setCurrentIndex] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchArticles = async () => {
      try {
        const response = await axios.get(
          "https://back.onekoocoffee.com/api/article/get-article"
        );
        setArticles(response.data);
        setLoading(false);
        console.table(response.data);
      } catch (error) {
        console.error("Error fetching articles:", error);
        setLoading(false);
      }
    };

    fetchArticles();
  }, []);

  const handleReadMore = (id) => {
    navigate(`/article/${id}`);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <main className="flex flex-col md:flex-row pt-40 md:pt-52 lg:pt-60 w-full h-full bg-white text-PrimaryGreen px-4 md:px-8">
      {/* Main Content */}
      <section className="flex-1 mb-6 md:mb-0 md:mr-8">
        <h1 className="text-2xl md:text-4xl font-bold text-primary mb-4">
          News and Articles
        </h1>

        {articles.length > 0 ? (
          <article className="flex flex-col w-full">
            <div className="flex flex-col md:flex-row w-full p-4 md:p-8 bg-white shadow-lg rounded-lg">
              <div className="flex-1 md:mr-4">
                <h2 className="text-lg md:text-2xl text-primary font-bold">
                  {articles[currentIndex].Title}
                </h2>
                <div className="flex flex-col md:flex-row mt-4">
                  <img
                    src={`https://back.onekoocoffee.com/images/${articles[currentIndex].Image}`}
                    alt={articles[currentIndex].Title}
                    className="h-60 md:h-[20rem] lg:h-[13rem] w-full object-cover rounded-md mx-auto md:mx-0"
                  />
                  <p className="mt-2 text-sm md:text-base text-primary text-start md:ml-4">
                    {articles[currentIndex].Ldescription}
                  </p>
                </div>
                <p className="mt-2 text-sm md:text-base text-primary text-start">
                  {articles[currentIndex].Bdescription}
                </p>
              </div>
            </div>
          </article>
        ) : (
          <p>No articles available</p>
        )}
      </section>

      {/* Sidebar */}
      <aside className="w-full md:w-[300px] md:max-w-[300px] h-auto md:h-full overflow-y-auto bg-gray-50 rounded-lg shadow-lg p-4 mt-6 md:mt-0">
        <h3 className="text-lg md:text-xl font-bold mb-4 text-primary">
          Recent News and Articles
        </h3>
        <ul className="space-y-4">
          {articles.map((article, index) => (
            <li
              key={article.id}
              className={`cursor-pointer p-2 transition ${
                index === currentIndex
                  ? "bg-golden text-primary rounded-md"
                  : "bg-primary text-white"
              }`}
              onClick={() => setCurrentIndex(index)}
            >
              <h4 className="font-semibold">{article.Title}</h4>
            </li>
          ))}
        </ul>
      </aside>
    </main>
  );
}

export default ArticleAndNews;
