import React from "react";
import CoffeeType from "./CoffeeType";
import packag from "../../../assets/images/whatDoWeDo/onekoo coffee packaging.png";

function PremiumPackaging() {
 

  const coffeeTypes = [
    {
      title: "Ground Coffee",
      description: "Available in 250 grams, 500 grams, 1 kg, and 2 kg.",
    },
    {
      title: "Roasted Coffee",
      description: "Available in 500 grams, 1 kg, and 2 kg.",
    },
  ];

  return (
    <section className="flex overflow-hidden flex-col justify-center items-center px-20 py-16 bg-white max-md:px-5">
      <div className="flex flex-col w-full max-w-[1103px] max-md:max-w-full">
        <h1 className="self-center text-4xl text-primary">  ONEKOO Coffee Package</h1>
        <div className="flex flex-col pl-3.5 mt-16 w-full max-md:mt-10 max-md:max-w-full">
          <p className="text-xl text-primary max-md:max-w-full">
        
          Experience the excellence of Ethiopian coffee with ONEKOO Coffee's premium packaged products, available in 250 g to 5kg bags. Perfect for both individual consumers and wholesale buyers, our coffee is meticulously sourced, roasted, and packaged to ensure the highest quality. Each bag contains carefully selected beans that capture the rich flavors and aromas of Ethiopia's renowned coffee regions. Committed to sharing the authentic taste of Ethiopian coffee with the world, ONEKOO Coffee's products are available internationally, allowing coffee enthusiasts everywhere to savor our exceptional blends.  </p>
          <div className="mt-10 ml-12 max-w-full w-[903px]">
            <div className="flex gap-20 max-md:flex-col">
             
              <img className="item-center justify-center sm:pr-5"
            loading="lazy"
            src={packag}
            alt="Coffee packaging"
           />
            </div>
          </div>
        </div>
        <div className="mt-10 w-full max-w-[1061px] max-md:mt-10 max-md:max-w-full">
          <div className="flex gap-5 max-md:flex-col">
            {coffeeTypes.map((type, index) => (
              <CoffeeType
                key={index}
                title={type.title}
                description={type.description}
              />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}

export default PremiumPackaging;
