import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Carousel from "../components/Contact/Carousel";
import Conta from "../components/Contact/Conta.jsx";
import Contacts from "../components/Contact/Contact";
import Conform from "../components/Contact/ContactForm";

const Contact = () => {
  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      const element = document.getElementById(location.hash.substring(1));
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);

  return (
    <React.Fragment>
      <div className="h-auto w-auto bg-white">
        <Carousel />
        {/* <Conta /> */}
        <Contacts />
        <Conform />
      </div>
    </React.Fragment>
  );
};

export default Contact;
