import React from "react";
import Hero from "../components/Home/Hero/Hero";
import Supply from "../components/Home/SupplyChain/SupplyChain";
import Product from "../components/Home/Our Coffee Products/CoffeeProducts";
import { motion } from "framer-motion";

const Home = () => {
  return (
    <div className="mt-32">
      <motion.div
        className="h-auto"
        initial={{ opacity: 0, y: -50 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6 }}
        viewport={{ once: false }}
      >
        <Hero />
      </motion.div>
    </div>
  );
};

export default Home;
