import React from "react";
import { motion } from "framer-motion";

// Component for each section of the content
const Section = ({ title, children }) => (
  <motion.div
    className="my-10 p-6 bg-white shadow-lg rounded-lg"
    initial={{ opacity: 0, y: 20 }}
    whileInView={{ opacity: 1, y: 0 }}
    viewport={{ once: true }}
    transition={{ duration: 0.5 }}
  >
    <h2 className="text-2xl font-bold text-PrimaryGreen mb-4">{title}</h2>
    <div className="text-lg text-primary">{children}</div>
  </motion.div>
);

const OnekooCoffeeRelationship = () => {
  return (
    <div className="container mx-auto px-4 sm:px-6 lg:px-8 py-10 pt-28">
      <Section title="Relationship to OCFCU">
        <p className="text-start text-xl">
          Onekoo Coffee is a product or brand of the Oromia Coffee Farmers'
          Cooperative Union (OCFCU), which is taking green coffee as input for
          the factory processing roasted, ground and packed coffee and mainly
          OCFCU used as a strategy for promoting different coffee taste to the
          domestic and international customers.
        </p>
      </Section>

      <Section title="Inputs">
        <p className="text-start text-xl">
          {/* List inputs here if there are any specific inputs to display. */}
          Green coffee sourced from Oromia, Ethiopia, processed and packed by
          OCFCU.
        </p>
      </Section>

      <Section title="Description of the relationship with OCFCU">
        <p className="text-start text-xl">
          Welcome to our series of cafés across Addis Ababa, where we invite you
          to savor the unparalleled flavor and aroma of Ethiopian coffee. Our
          flagship product, Onekoo Coffee, is crafted by the Oromia Coffee
          Farmers' Cooperative Union, located in Oromia, the very birthplace of
          coffee.
        </p>
        <p className="text-start text-xl mt-4">
          Rooted in a rich history, our coffee represents a gift from the
          Ethiopian people to the world. From its origins in Oromia, coffee has
          traveled far and wide, influencing global coffee culture and becoming
          a symbol of quality and craftsmanship.
        </p>
        <p className="text-start text-xl mt-4">
          Our cafés are more than just places to enjoy a cup of coffee—they are
          vibrant spaces where the legacy of Ethiopian coffee comes alive.
          Beyond its well-known caffeine content, coffee is a treasure trove of
          flavors, aromas, and cultural significance. It serves as a bridge for
          negotiations, a catalyst for new friendships, and a cornerstone for
          both personal and professional connections.
        </p>
        <p className="text-start text-xl mt-4">
          Coffee is integral to our business, our economy, and our daily lives.
          It embodies passion, tradition, and a commitment to excellence. As you
          visit our cafés, you will not only taste exceptional coffee but also
          experience a story that is woven into the fabric of our heritage.
        </p>
        <p className="text-start text-xl mt-4">
          Join us in celebrating this journey and let us share with you the rich
          narrative of our coffee. Discover the depth of our story in every sip.
          Welcome to a new chapter in the world of Ethiopian coffee—taste our
          story today with Onekoo Coffee.
        </p>
      </Section>

      <Section title="Participating in Festivals and Trade Shows">
        <p className="text-start text-xl">
          We participate in various festivals and trade shows to:
        </p>
        <ul className="list-disc pl-5 mt-2 space-y-2 text-start">
          <li>Retain existing customers and create new customers.</li>
          <li>Raise awareness among customers.</li>
          <li>Make our products available to customers.</li>
        </ul>
      </Section>

      <Section title="Collaborative Projects or Initiatives">
        <p className="text-start text-xl">
          As we know, Onekoo Coffee is a product or brand of the Oromia Coffee
          Farmers' Cooperative Union. We announce their vision, mission, and
          objectives at various events, such as international and domestic trade
          fairs, training, and other events. We also hold joint quarterly and
          yearly meetings to evaluate our work and strategize for the future.
        </p>
      </Section>

      {/* You can add more sections as needed */}
    </div>
  );
};

export default OnekooCoffeeRelationship;
