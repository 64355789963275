import React from "react";
import hero1 from "../../assets/onekoo mediea.png";

function Banner() {
  const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000,
    fade: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    waitForAnimate: false,
    className: "w-full",
  };

  return (
    <div className="slider-container">
      <div className="relative overflow-hidden shadow-lg shadow-white">
        <img
          className="object-cover w-full h-[20rem] md:h-[40rem] lg:h-[40rem]"
          src={hero1}
          alt="Flower and sky"
        />
        <div className="absolute -top-20 md:top-0 left-0 w-full flex justify-center items-center pt-36 px-6 py-4">
          <h4 className="mt-10 md:mt-4 ml-6 md:ml-2 w-fit text-5xl md:text-7xl p-4 md:p-8 text-primary font-bold tracking-tight">
            Media
          </h4>
        </div>
      </div>
    </div>
  );
}

export default Banner;
