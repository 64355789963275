import React, { useState } from "react";
import { motion } from "framer-motion";
import dereje from "../../assets/images/Story/dereje.png";
import dejene from "../../assets/images/Story/dejene.png";

const profiles = [
  {
    name: "Dereje Hirpa",
    title: "Coffee Quality Control & Extension Department Head",
    image: dereje,
    email: "derejehirpa@gmail.com",
    phone: "+251912345678",
  },
  {
    name: "Dejene Dadi",
    title: "General Manager",
    image: dejene,
    email: "derejehirpa@gmail.com",
    phone: "+251912345678",
  },
  {
    name: "Solomon Tadele",
    title: "Manager",
    image: "https://via.placeholder.com/150",
    email: "",
    phone: "+251912345678",
  },
  {
    name: "Pawulos Deksisa",
    title: "Finanace & Admin Div.Head",
    image: "https://via.placeholder.com/150",
    email: "",
    phone: "+251912345678",
  },
  {
    name: "Nigatu Dinku",
    title: "Marketing Div.Head",
    image: "https://via.placeholder.com/150",
    email: "",
    phone: "+251912345678",
  },
  {
    name: "Tesfaye Girma",
    title: "Production Div.Head",
    image: "https://via.placeholder.com/150",
    email: "",
    phone: "+251912345678",
  },
];

const ProfileCarousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? profiles.length - 1 : prevIndex - 1
    );
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === profiles.length - 1 ? 0 : prevIndex + 1
    );
  };

  return (
    <div className="w-full max-w-md mx-auto mt-10 p-4">
      <div id="team" className="text-4xl font-bold text-primary my-16">
        Our members and staff
      </div>
      <div className="relative">
        <motion.div
          key={currentIndex}
          initial={{ opacity: 0, x: 100 }}
          animate={{ opacity: 1, x: 0 }}
          exit={{ opacity: 0, x: -100 }}
          transition={{ duration: 0.5 }}
          className="bg-white p-6 rounded-lg shadow-lg flex flex-col items-center text-center"
        >
          <img
            className="w-24 h-24  mb-4"
            src={profiles[currentIndex].image}
            alt={profiles[currentIndex].name}
          />
          <h3 className="text-2xl font-bold text-primary">
            {profiles[currentIndex].name}
          </h3>
          <p className="text-2xl text-primary mb-4">
            {profiles[currentIndex].title}
          </p>
          <p className="text-primary text-xl">{profiles[currentIndex].phone}</p>
          <p className="text-primary text-xl">{profiles[currentIndex].email}</p>
        </motion.div>

        {/* Previous Button */}
        <button
          onClick={handlePrev}
          className="absolute top-1/2 left-0 transform -translate-y-1/2 bg-primary text-white p-2 rounded-full focus:outline-none"
        >
          &#8592;
        </button>

        {/* Next Button */}
        <button
          onClick={handleNext}
          className="absolute top-1/2 right-0 transform -translate-y-1/2 bg-primary text-white p-2 rounded-full focus:outline-none"
        >
          &#8594;
        </button>
      </div>

      {/* Indicators */}
      <div className="flex justify-center mt-4 space-x-2">
        {profiles.map((_, index) => (
          <button
            key={index}
            onClick={() => setCurrentIndex(index)}
            className={`w-3 h-3 rounded-full ${
              currentIndex === index ? "bg-primary" : "bg-orange-900"
            }`}
          ></button>
        ))}
      </div>
    </div>
  );
};

export default ProfileCarousel;
