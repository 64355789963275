import React from "react";

const ImageWithText = ({ imageSrc, title, content }) => (
  <div className="flex flex-col md:flex-row gap-5 md:gap-10 md:items-start sm:items-center sm:text-center mt-24 ml-3.5 w-full text-xl text-white md:text-start max-w-[1161px] max-md:mt-10 max-md:max-w-full">
    <img
      loading="lazy"
      src={imageSrc}
      alt={title}
      className="object-contain md:object-cover sm:ml-32 items-center mt-5 md:mt-0 w-[130px] h-40 md:w-[200px] flex-shrink-0"
      />
    <div className="flex flex-col grow shrink-0 items-center  basis-0 w-fit max-md:max-w-full">
      <h3 className="self-start font-bold max-md:max-w-full">{title}</h3>
      <p className="mt-7 max-md:max-w-full">{content}</p>
    </div>
  </div>
);

export default ImageWithText;
