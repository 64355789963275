import React from 'react';

function ImageCard({ image, name }) {
  return (
    <article className="flex flex-col w-[33%] max-md:ml-0 max-md:w-full">
      {image ? (
        <img loading="lazy" src={image} alt={`${name} product`} className="object-contain grow shrink-0 mt-1.5 max-w-full aspect-[0.61] w-[274px] max-md:mt-10" />
      ) : (
        <div className="flex shrink-0 max-w-full h-[456px] w-[274px] max-md:mt-10" />
      )}
    </article>
  );
}

export default ImageCard;