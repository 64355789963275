// import React from 'react';
// import { Link } from 'react-router-dom';

// function ProductCard({ image, weight, name }) {
//   return (
//     <article className="flex flex-col w-3/12 max-md:ml-0 max-md:w-full">
//       <div className="flex flex-col grow items-center text-3xl font-bold text-center max-md:mt-10">
//         {image && <img loading="lazy" src={image} alt={`${weight || name} product`} className="self-stretch  w-32 h-32 rounded-full object-cover aspect-[0.82]" />}
//         {weight && <div className="mt-3.5 text-primary text-xl">{weight}</div>}
//         {name && <div className="self-center text-primary text-xl">{name}</div>}
//         <button className="px-8 py-3 mt-4 max-w-full text-white bg-golden rounded-[30px] w-auto max-md:px-5">
//           <Link
//             className='text-xl mx-auto'
//             onClick={() => window.scrollTo(0, 0)}
//             to={`/pre-shop`}
//           >
//             Buy Now
//           </Link>
//         </button>
//       </div>
//     </article>
//   );
// }

// export default ProductCard;
import React from 'react';
import { Link } from 'react-router-dom';

function ProductCard({ image, weight, name }) {
  return (
    <article className="flex flex-col items-center w-3/12 max-md:w-full max-md:mt-10">
      <div className="flex flex-col items-center text-center text-3xl font-bold">
        {image && (
          <img
            loading="lazy"
            src={image}
            alt={`${weight || name} product`}
            className="w-32 h-32 rounded-full object-cover aspect-[0.82]"
          />
        )}
        {weight && <div className="mt-3.5 text-primary text-xl">{weight}</div>}
        {name && <div className="mt-1 text-primary text-xl">{name}</div>}
        <button className="px-8 py-3 mt-4 text-white bg-[#094709] hover:bg-[#155715] rounded-[30px] max-md:px-5">
          <Link
            className="text-xl mx-auto"
            onClick={() => window.scrollTo(0, 0)}
            to={`/pre-shop`}
          >
            Buy Now
          </Link>
        </button>
      </div>
    </article>
  );
}

export default ProductCard;
