import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import axios from "axios";

const Awards = () => {
  const [awards, setAwards] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null); // State for error handling

  useEffect(() => {
    const fetchAwards = async () => {
      try {
        const response = await axios.get(
          "https://back.onekoocoffee.com/api/award/get-awards"
        );
        setAwards(response.data);
        console.table(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching Awards:", error);
        setError("Failed to load awards. Please try again later."); // Set error message
        setLoading(false);
      }
    };

    fetchAwards();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>; // Display error message if fetching fails
  }

  return (
    <div className="container mx- px-4 py-12">
      <h1 className="text-4xl font-bold text-center text-primary mb-8">
        Our Awards
      </h1>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-8">
        {awards.map((award) => (
          <motion.div
            key={award.id}
            className="p-6 bg-white rounded-lg shadow-lg flex flex-col md:flex-row"
            whileHover={{ scale: 1.05 }}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            <img
              src={`https://back.onekoocoffee.com/images/${award.Image}`}
              alt={award.Title}
              className="w-full h-48 md:w-48 md:h-full object-cover rounded-md mb-4 md:mb-0 md:mr-6"
              loading="lazy" // Lazy loading for better performance
            />
            <div className="flex flex-col justify-between">
              <h2 className="text-2xl font-semibold mb-2 text-primary">
                {award.Title}
              </h2>
              <p className="text-primary text-xl text-start">{award.Description}</p>
            </div>
          </motion.div>
        ))}
      </div>
    </div>
  );
};

export default Awards;
