import React, { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import Supply from "../components/Home/SupplyChain/SupplyChain";
import Product from "../components/Home/Our Coffee Products/CoffeeProducts";
import FarmToCup from "../components/Story/FarmToCup/CoffeeSupplyChain";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import ImageSection from "../components/Home/SupplyChain/ImageSection";
import community from "../assets/FARMERS_COMMUNITY_Onekoo_2024_PSYANN_GRAPHICS.png";
import enviroment from "../assets/ECO_FRIENDLY_ONEKOO_PSYANN_GRAPHICS.png";

const FarmToCupMain = () => {
  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      const element = document.getElementById(location.hash.substring(1));
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);

  return (
    <div className="pt-20">
      <motion.div
        className="h-full mb-[85rem] sm:mb-[68rem] md:mb-[30rem] lg:mb-[30rem]"
        initial={{ opacity: 0, y: 50 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6 }}
        viewport={{ once: true }}
      >
        <Supply />
      </motion.div>
      <motion.div>
        <FarmToCup />
      </motion.div>
      <motion.div
        className="h-full"
        initial={{ opacity: 0, y: 50 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6 }}
        viewport={{ once: true }}
      >
        <Product />
      </motion.div>
      {/* community initiatives */}
      <main className="flex flex-col px-12 pt-20 pb-7 bg-white max-md:px-5 mb-[1300px] md:mb-[500px]">
        <header id="community-initiatives" className="self-center">
          <h1 className="text-4xl text-primary">Community Initiatives</h1>
        </header>
        <section className="flex gap-5 max-md:flex-col max-md:max-w-full">
          {/* <TextSection
          content={textSections[0].content}
          readMore={textSections[0].readMore}
        /> */}
          <article className="flex flex-col w-6/12 max-md:ml-0 max-md:w-full">
            <div className="flex flex-col grow mt-14 text-xl max-md:mt-10 max-md:max-w-full">
              <p className="text-primary max-md:max-w-full text-start">
                ONEKOO Coffee is dedicated to preserving the environment through
                sustainable practices at every step of our coffee production
                process. We promote eco-friendly farming methods and strive to
                reduce our carbon footprint by minimizing waste and utilizing
                environmentally responsible packaging. Our collaboration with
                the Oromia Coffee Farmers' Cooperative Union (OCFCU) ensures
                that our coffee is grown with respect for the land and the
                people who cultivate it. By choosing ONEKOO Coffee, you are
                supporting a brand committed to environmental stewardship and
                the long-term health of our planet. Together, we can enjoy
                exceptional coffee while making a positive impact on the
                environment.
              </p>
            </div>
          </article>
          <ImageSection
            src={community}
            alt="Coffee supply chain illustration"
          />
        </section>
        {/* enviromental promises */}
        <section className="flex gap-2 mt-5 max-md:flex-col max-md:mr-1 max-md:max-w-full">
          <ImageSection src={enviroment} alt="Coffee cafe illustration" />
          {/* <TextSection
          content={textSections[1].content}
          readMore={textSections[1].readMore}
        /> */}
          <article className="flex flex-col w-6/12 max-md:ml-0 max-md:w-full">
            <div
              id="environmental-promise"
              className="flex flex-col grow mt-14 text-xl max-md:mt-10 max-md:max-w-full"
            >
              <h1 className="text-4xl text-primary mb-6">
                Environmental Promises
              </h1>
              <p className="text-primary max-md:max-w-full text-start">
                ONEKOO Coffee is dedicated to preserving the environment through
                sustainable practices at every step of our coffee production
                process. We promote eco-friendly farming methods and strive to
                reduce our carbon footprint by minimizing waste and utilizing
                environmentally responsible packaging. Our collaboration with
                the Oromia Coffee Farmers' Cooperative Union (OCFCU) ensures
                that our coffee is grown with respect for the land and the
                people who cultivate it. By choosing ONEKOO Coffee, you are
                supporting a brand committed to environmental stewardship and
                the long-term health of our planet. Together, we can enjoy
                exceptional coffee while making a positive impact on the
                environment.
              </p>
            </div>
          </article>
        </section>
      </main>
    </div>
  );
};

export default FarmToCupMain;
