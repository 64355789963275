import React from "react";

function ImageSection({ src, alt }) {
  return (
    <figure className="flex flex-col ml-0 w-10/12 max-md:ml-0 max-md:w-full">
      <img
        loading="lazy"
        src={src}
        alt={alt}
        className="object-cover md:object-contain w-full md:aspect-[1.3] max-md:mt-10 max-md:max-w-full"
      />
    </figure>
  );
}

export default ImageSection;
