import React from "react";
import product1 from "../../../assets/images/FarmtoCup/4.png";
import product from "../../../assets/onekoo_coffee_experience_.png";

function CoffeeImage() {
  return (
    <aside className="flex flex-col ml-5 w-[40%] max-md:ml-0 max-md:w-full">
      <img
        loading="lazy"
        src={product}
        alt="Coffee product showcase"
        className="object-contain grow mt-32 w-full aspect-[0.95] max-md:mt-10 max-md:max-w-full"
      />
    </aside>
  );
}

export default CoffeeImage;
