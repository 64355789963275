import React from "react";
import { Link } from "react-router-dom";
import roastChart from "../../../assets/images/whatDoWeDo/onekoo_coffee_roast_chart.png";

const CoffeeRoastList = () => (
  <div className="mt-12 flex flex-col items-center mx-auto max-w-full px-4">
    {/* Responsive, centered image */}
    <div className="flex justify-center w-full">
      <img 
        src={roastChart} 
        alt="Roast" 
        className="w-full max-w-[500px] sm:w-[400px] md:w-[500px] lg:w-[600px]" // Adjusts for different screen sizes
      />
    </div>

    <button className="px-6 py-2 mt-10 bg-yellow-900 rounded-3xl text-xl text-white">
      <Link
        onClick={() => window.scrollTo(0, 0)}
        to={"/whatwedo"}
      >
        Discover Our Coffee Journey
      </Link>
    </button>
  </div>
);

export default CoffeeRoastList;
