import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Banner from "../components/Story/Banner";
import Intro from "../components/Story/Intro";
import Carousel from "../components/Story/Carousel";
import Tab from "../components/Story/Tab"; // Corrected the import from 'Tob' to 'Tab'
// import FarmToCup from "../components/Story/FarmToCup/CoffeeSupplyChain";
import WhatWeDo from "../components/Story/WhatWeDo/WhatWeDo";

const Story = () => {

  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      const element = document.getElementById(location.hash.substring(1));
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);
  return (
    <React.Fragment>
      <div className="self-center gap-10 mt-32 bg-white overflow-x-hidden">
        <Banner />
        <Intro />
        <div className="mt-10 mb-16">
          <WhatWeDo />
        </div>
        <div className="w-auto mx-auto">
          <Tab />
          <Carousel />
        </div>
      </div>
    </React.Fragment>
  );
};

export default Story;
