import React from "react";

function SectionTitle({ mainTitle, subTitle, className = "" }) {
  return (
    <div
      id="our-coffee-products"
      className={`flex flex-col self-center ${className}`}
    >
      <h2 className="text-4xl">{mainTitle}</h2>
      {subTitle && (
        <h3 className="self-center mt-9 text-2xl italic">{subTitle}</h3>
      )}
    </div>
  );
}

export default SectionTitle;
