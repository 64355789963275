import React from 'react';
import { FaLocationDot } from "react-icons/fa6";
import { FaPhone } from "react-icons/fa";
import { MdOutlineEmail } from "react-icons/md";

function ShopCard({ image, name, location, phone, email }) {
  return (
    <article className="flex flex-col w-6/12 max-md:ml-0 max-md:w-full">
      <div className="flex flex-col grow pb-8 w-full text-base bg-primary rounded-3xl text-white max-md:mt-10 max-md:max-w-full">
        <img loading="lazy" src={image} alt={`${name} shop front`} className="object-cover w-full rounded-t-3xl aspect-[1.81] max-md:max-w-full" />
        <div className="flex flex-col items-start self-start mt-6 ml-20 max-md:ml-2.5">
          <h3 className="self-stretch flex flex-row gap-3 text-xl"><FaLocationDot />{location}</h3>
          <p className="mt-7 flex flex-row gap-3 text-xl"><FaPhone />{phone}</p>
          <p className="mt-7 flex flex-row gap-3 text-xl"><MdOutlineEmail />{email}</p>
        </div>
      </div>
    </article>
  );
}

export default ShopCard;