import React from "react";

function CoffeeType({ title, description }) {
  return (
    <div className="flex flex-col w-6/12 max-md:ml-0 max-md:w-full rounded-3xl">
      <div className="flex flex-col grow px-12 pt-8 pb-28 w-full bg-secondary rounded-full text-white max-md:px-5 max-md:pb-24 max-md:mt-10 max-md:max-w-full">
        <h2 className="self-center text-4xl">{title}</h2>
        <p className="mt-14 text-3xl max-md:mt-10">{description}</p>
      </div>
    </div>
  );
}

export default CoffeeType;
