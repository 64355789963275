import React from "react";
import hero1 from "../../assets/images/farm-to-cup-hero.jpg";

function Banner() {
  const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000,
    fade: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    waitForAnimate: false,
    className: "w-full",
  };

  return (
    <div className="slider-container mt-0">
      <div className="relative overflow-hidden shadow-lg shadow-white">
        <img
          className="object-cover w-full h-auto"
          src={hero1}
          alt="Flower and sky"
        />
        <div className="absolute -top-20 md:top-0 left-0 w-full flex justify-center items-center pt-36 px-6 py-4">
          <h4 className="mt-10 md:mt-24 ml-6 md:ml-28 w-fit text-4xl md:text-7xl shadow-lg bg-primary bg-opacity-30 p-4 md:p-8 rounded-2xl text-white font-semibold tracking-tight">
            Farm To Cup
          </h4>
        </div>
      </div>
    </div>
  );
}

export default Banner;
