import React from "react";
import ProductDescription from "./ProductDescription";
import SectionTitle from "./SectionTitle";
import CoffeeImage from "./CoffeeImage";

function CoffeeProducts() {
  const descriptions0 = [
    {
      text: "Immerse yourself in the ultimate coffee journey at ONEKOO Coffee, where the essence of Ethiopian culture is celebrated in every cup. Visit our inviting cafés in Addis Ababa, including prime locations at the Ethiopian Airport and Addis Ababa International Airport, and indulge in a world of exceptional coffee drinks. Savor our bittersweet espressos, traditionally cupped coffee, frothy macchiatos, and creamy lattes—all crafted to perfection from the finest Ethiopian Arabica beans.",
      highlight: false,
    },
  ];
  const descriptions1 = [
    {
      text: "Proudly partnered with the Oromia Coffee Farmers' Cooperative Union, ONEKOO Coffee is dedicated to expanding its reach, bringing our exquisite flavors to coffee enthusiasts around the world. Our cozy, inviting atmosphere is perfect for social gatherings, business meetings, or a quiet place to work, offering a unique and immersive experience that celebrates the rich heritage and flavors of Ethiopia.",
      highlight: false,
    },
  ];

  return (
    // <main className=" pt-20 pb-7 bg-secondary text-center max-md:px-5 max-md:pb-24">
    <div className="px-8 py-12 flex gap-5 max-md:flex-col bg-secondary">
      <section className="flex flex-col w-[60%] max-md:ml-0 max-md:w-full">
        <div className="flex flex-col grow text-center items-start text-white max-md:mt-10 max-md:max-w-full">
          <SectionTitle
            mainTitle="Our Coffee Products"
            subTitle="Taste Our Story"
          />
          {descriptions0.map((desc, index) => (
            <ProductDescription
              key={index}
              text={desc.text}
              highlight={desc.highlight}
            />
          ))}
          <SectionTitle
            subTitle="Experience our coffee first hand"
            className="mt-16 ml-20 text-3xl text-primary max-md:mt-10 max-md:max-w-full"
          />
          {descriptions1.map((desc, index) => (
            <ProductDescription
              key={index}
              text={desc.text}
              highlight={desc.highlight}
            />
          ))}
          <SectionTitle
            subTitle="Enjoy a taste of excellence with Onekoo Coffee"
            className="mt-16 text-3xl text-primary max-md:mt-10 max-md:max-w-full"
          />
        </div>
      </section>
      <CoffeeImage />
    </div>
    // </main>
  );
}

export default CoffeeProducts;
