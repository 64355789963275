import React, { useState } from "react";
import { motion } from "framer-motion";
import bole from "../../assets/images/boleBranch.jpg";
import global from "../../assets/images/global.jpg";
import ldeta from "../../assets/images/ldeta.jpg";
import airport from "../../assets/images/airport.jpg";

const branches = [
  {
    image: global,
    name: "Global Branch",
    location: "Located at Mikwer Plaza",
    phone: "+251 - 94 - 978 - 2549 / 50 / 51",
    email: "globalbranch@onekoocoffee.com",
  },
  {
    image: bole,
    name: "Bole Branch",
    location: "Located at Africa Development Bank building",
    phone: "+251 - 94 - 978 - 2549 / 50 / 51",
    email: "bolebranch@onekoocoffee.com",
  },
  {
    image: airport,
    name: "Airport branch",
    location: "Located at Addis Ababa Airport",
    phone: "+251 - 94 - 978 - 2549 / 50 / 51",
    email: "airportbanch@onekoocoffee.com",
  },
  {
    image: ldeta,
    name: "Lideta Branch",
    location: "Located at Awash Bank building",
    phone: "+251 - 94 - 978 - 2549 / 50 / 51",
    email: "ldetabranch@onekoocoffee.com",
  },
];

const BranchCarousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? branches.length - 1 : prevIndex - 1
    );
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === branches.length - 1 ? 0 : prevIndex + 1
    );
  };

  return (
    <div className="w-full max-w-4xl max-h-xl mx-2 mt-10 p-4">
      <div className="text-4xl font-bold text-primary my-6">
        Our Coffee Branches
      </div>
      <div className="relative px-6">
        <motion.div
          key={currentIndex}
          initial={{ opacity: 0, x: 100 }}
          animate={{ opacity: 1, x: 0 }}
          exit={{ opacity: 0, x: -100 }}
          transition={{ duration: 0.5 }}
          className="bg-white p-6 rounded-lg shadow-lg flex flex-col items-center text-center min-h-[400px]"
        >
          <img
            className="w-full h-[400px] border rounded-lg mb-4"
            src={branches[currentIndex].image}
            alt={branches[currentIndex].name}
          />
          <h3 className="text-2xl font-bold text-primary">{branches[currentIndex].name}</h3>
          <p className="text-2xl text-primary mb-4">
            {branches[currentIndex].title}
          </p>
          <p className="text-primary text-xl">{branches[currentIndex].phone}</p>
          <p className="text-primary text-xl">{branches[currentIndex].email}</p>
        </motion.div>

        {/* Previous Button */}
        <button
          onClick={handlePrev}
          className="absolute top-1/2 left-0 transform -translate-y-1/2 bg-primary text-white p-2 rounded-full focus:outline-none"
        >
          &#8592;
        </button>

        {/* Next Button */}
        <button
          onClick={handleNext}
          className="absolute top-1/2 right-0 transform -translate-y-1/2 bg-primary text-white p-2 rounded-full focus:outline-none"
        >
          &#8594;
        </button>
      </div>

      {/* Indicators */}
      <div className="flex justify-center mt-4 space-x-2">
        {branches.map((_, index) => (
          <button
            key={index}
            onClick={() => setCurrentIndex(index)}
            className={`w-3 h-3 rounded-full ${
              currentIndex === index ? "bg-primary" : "bg-orange-900"
            }`}
          ></button>
        ))}
      </div>
    </div>
  );
};

export default BranchCarousel;
