import React from "react";
import { FaPhone, FaEnvelope, FaFax, FaMapMarkerAlt } from "react-icons/fa";
import { Link } from "react-router-dom";
import { BsTwitterX } from "react-icons/bs";
import { FaLinkedin, FaInstagram, FaFacebookSquare } from "react-icons/fa";
import BranchCarousel from "./carouselBranch"
const Contact = () => {
  return (
    <div className="overflow-hidden bg-white">
      {/* Page Title */}
      <div className="text-center pt-24 pb-12 bg-primary text-white">
        <h1 className="text-5xl md:text-6xl font-extrabold">Contact Us</h1>
        <p className="text-lg mt-4">
        We’d love to hear from you! Contact us for business inquiries, partnerships, or to explore more about our roasted and ground coffee.
        </p>
      </div>

      {/* Main Contact Section */}
      <div id="main-office" className="flex flex-col md:flex-row mt-16 px-4 lg:px-20">
        {/* Left Side - Contact Information */}
        <div className="w-full md:w-1/2 px-4 mb-10">
          <h2 className="text-4xl font-bold text-primary mb-8">Main Office</h2>
          <div className="space-y-6 text-primary">
            <div className="flex items-center">
              <FaMapMarkerAlt className="text-xl text-primary mr-4" />
              <p className="text-primary">Gelan Sub City, Oromia, Ethiopia</p>
            </div>
            <div className="flex items-center">
              <FaPhone className="text-xl text-primary mr-4" />
              <a href="tel:+251114450488" className="text-primary">
                +251-11-445-0488
              </a>
            </div>
            <div className="flex items-center">
              <FaFax className="text-xl text-primary mr-4" />
              <p className="text-primary">Fax: +251-11-445-0489</p>
            </div>
            <div className="flex items-center">
              <FaEnvelope className="text-xl text-primary mr-4" />
              <a href="mailto:info@coffeeexport.com" className="text-primary">
                info@coffeeexport.com
              </a>
            </div>
            <div className="flex items-center">
              <FaMapMarkerAlt className="text-xl text-primary mr-4" />
              <p className="text-primary">P.O. Box: 1394, Addis Ababa, Ethiopia</p>
            </div>
          </div>

          {/* Call to Action Buttons */}
          {/* <div className="flex flex-col space-y-4 mt-10">
            <Link
              to="/shop"
              className="bg-golden text-white font-bold py-3 px-6 rounded-lg transition duration-300 text-center"
            >
              Buy Our Coffee
            </Link>
            <Link
              to="/our-story"
              className="bg-golden text-white font-bold py-3 px-6 rounded-lg transition duration-300 text-center"
            >
              Visit Us
            </Link>
          </div> */}

          {/* Social Media Links */}
          {/* <div className="flex space-x-4 mt-6 mx-auto">
            <a
              href="https://www.facebook.com"
              target="_blank"
              rel="noreferrer"
              className="rounded-full h-20 w-20 flex items-center justify-center text-primary transition duration-300"
            >
              <FaFacebookSquare className="w-10 h-10" />
            </a>
            <a
              href="https://www.linkedin.com/company/coffee-export"
              target="_blank"
              rel="noreferrer"
              className="rounded-full h-20 w-20 flex items-center justify-center text-primary transition duration-300"
            >
              <FaLinkedin className="w-10 h-10" />
            </a>
            <a
              href="https://www.linkedin.com/company/coffee-export"
              target="_blank"
              rel="noreferrer"
              className="rounded-full h-20 w-20 flex items-center justify-center text-primary transition duration-300"
            >
              <FaInstagram className="w-10 h-10" />
            </a>
            <a
              href="https://www.linkedin.com/company/coffee-export"
              target="_blank"
              rel="noreferrer"
              className="rounded-full h-20 w-20 flex items-center justify-center text-primary transition duration-300"
            >
              <BsTwitterX className="w-10 h-10" />
            </a>
          </div> */}
        </div>

        {/* Right Side - Google Map */}
        <div className="w-full md:w-1/2 px-4">
          <iframe
            className="h-96 w-full border-4 border-gray-200 rounded-lg shadow-lg"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1079.8815920651168!2d38.84236245200944!3d8.829936780449671!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x164b77def32dc139%3A0x9e4813b35be0d351!2sOromia%20Coffee%20Farmers&#39;%20Cooperative%20Union!5e1!3m2!1sen!2set!4v1720673059271!5m2!1sen!2set"
            allowFullScreen=""
            loading="lazy"
            title="Coffee Export Location"
          ></iframe>
        </div>
      </div>
      <div className="flex flex-col md:flex-row mt-16 px-4 lg:px-20">
        {/* Left Side - Contact Information */}
        <div id="marketing-department" className="w-full md:w-1/2 px-4 mb-10">
          <h2 className="text-4xl font-bold text-primary mb-8">Marketing Department Office</h2>
          <div className="space-y-6 text-primary">
            <div className="flex items-center">
              <FaMapMarkerAlt className="text-xl text-primary mr-4" />
              <p className="text-primary">Gelan Sub City, Oromia, Ethiopia</p>
            </div>
            <div className="flex items-center">
              <FaPhone className="text-xl text-primary mr-4" />
              <a href="tel:+251114450488" className="text-primary">
                +251-11-445-0488
              </a>
            </div>
            <div className="flex items-center">
              <FaFax className="text-xl text-primary mr-4" />
              <p className="text-primary">Fax: +251-11-445-0489</p>
            </div>
            <div className="flex items-center">
              <FaEnvelope className="text-xl text-primary mr-4" />
              <a href="mailto:info@coffeeexport.com" className="text-primary">
                info@coffeeexport.com
              </a>
            </div>
            <div className="flex items-center">
              <FaMapMarkerAlt className="text-xl text-primary mr-4" />
              <p className="text-primary">P.O. Box: 1394, Addis Ababa, Ethiopia</p>
            </div>
          </div>

          
        </div>

        {/* Right Side - Google Map */}
        <div className="w-full md:w-1/2 px-4">
          <iframe
            className="h-96 w-full border-4 border-gray-200 rounded-lg shadow-lg"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1079.8815920651168!2d38.84236245200944!3d8.829936780449671!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x164b77def32dc139%3A0x9e4813b35be0d351!2sOromia%20Coffee%20Farmers&#39;%20Cooperative%20Union!5e1!3m2!1sen!2set!4v1720673059271!5m2!1sen!2set"
            allowFullScreen=""
            loading="lazy"
            title="Coffee Export Location"
          ></iframe>
        </div>
      </div>
      <div className="flex flex-col md:flex-row justify-between items-start mt-0 px-4 lg:px-8 gap-0">
  {/* Left Section: Google Map Carousel */}
  <div id="coffee-shop-branch" className="w-full md:w-2/3 lg:w-3/5 h-full px-0">
    <BranchCarousel />
  </div>

  {/* Right Section: Links and Social Media */}
  <div className="flex flex-col items-center md:items-start w-full md:w-1/3 lg:w-3/6 ml-0 mr-16 space-y-6 mt-10 md:mt-40">
    {/* Buy and Visit Links */}
    <div className="flex flex-col space-y-4 w-full">
      <Link
        to="/shop"
        className="bg-primary hover:bg-secondary text-white font-bold py-3 px-6 rounded-lg transition duration-300 text-center w-full"
      >
        Buy Our Coffee
      </Link>
      <Link
        to="/our-story"
        className="bg-primary hover:bg-secondary text-white font-bold py-3 px-6 rounded-lg transition duration-300 text-center w-full"
      >
        Visit Us
      </Link>
    </div>

    {/* Social Media Links */}
    <div className="flex space-x-4 mt-6">
      <a
        href="https://www.facebook.com"
        target="_blank"
        rel="noreferrer"
        className="rounded-full h-14 w-14 flex items-center justify-center text-primary transition duration-300"
      >
        <FaFacebookSquare className="w-8 h-8" />
      </a>
      <a
        href="https://www.linkedin.com/company/coffee-export"
        target="_blank"
        rel="noreferrer"
        className="rounded-full h-14 w-14 flex items-center justify-center text-primary transition duration-300"
      >
        <FaLinkedin className="w-8 h-8" />
      </a>
      <a
        href="https://www.instagram.com/company/coffee-export"
        target="_blank"
        rel="noreferrer"
        className="rounded-full h-14 w-14 flex items-center justify-center text-primary transition duration-300"
      >
        <FaInstagram className="w-8 h-8" />
      </a>
      <a
        href="https://www.twitter.com/company/coffee-export"
        target="_blank"
        rel="noreferrer"
        className="rounded-full h-14 w-14 flex items-center justify-center text-primary transition duration-300"
      >
        <BsTwitterX className="w-8 h-8" />
      </a>
    </div>
  </div>
</div>

      {/* Footer Information */}
      <div className="bg-primary text-white py-12 mt-16">
        <div className="container mx-auto text-center">
          <p className="text-lg font-semibold">
          Interested in becoming a global distributor of our premium roasted and ground coffee?
          </p>
          <Link
            to="/contact-us"
            className="inline-block mt-4 bg-golden hover:bg-yellow-700 text-white font-bold py-3 px-8 rounded-lg transition duration-300"
          >
            Contact Us Today
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Contact;
