import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import ShopCard from "./ShopCard";
import ProductSection from "./ProductSection";
import ProductCard from "./ProductCard";
import ImageCard from "./ImageCard";
import bole from "../../assets/images/boleBranch.jpg";
import global from "../../assets/images/global.jpg";
import ldeta from "../../assets/images/ldeta.jpg";
import airport from "../../assets/images/airport.jpg";
import hot from "../../assets/onekoo packaging/onekoo cup.png";
import dabo from "../../assets/images/dabo.png";
import kolo from "../../assets/images/kolo.png";
import ground from "../../assets/onekoo_coffee_packaging_front_psyann_graphics_2024.png";
// import roast from "../../assets/images/roasted.jpg";
import { Link } from "react-router-dom";
import LogoBrown from "../../assets/images/onekoo coffee text brown.webp";
import roastedImg from "../../assets/onekoo_coffee_roasted_coffee.png";
import ImageSection from "../Home/SupplyChain/ImageSection2";
import menu from "../../assets/onekoo_coffee_menu_psyann_graphics_2024.png";

const shopData = [
  {
    image: global,
    name: "Global Branch",
    location: "Located at Mikwer Plaza",
    phone: "+251 - 94 - 978 - 2549 / 50 / 51",
    email: "globalbranch@onekoocoffee.com",
  },
  {
    image: bole,
    name: "Bole Branch",
    location: "Located at Africa Development Bank building",
    phone: "+251 - 94 - 978 - 2549 / 50 / 51",
    email: "bolebranch@onekoocoffee.com",
  },
  {
    image: airport,
    name: "Airport branch",
    location: "Located at Addis Ababa Airport",
    phone: "+251 - 94 - 978 - 2549 / 50 / 51",
    email: "airportbanch@onekoocoffee.com",
  },
  {
    image: ldeta,
    name: "Lideta Branch",
    location: "Located at Awash Bank building",
    phone: "+251 - 94 - 978 - 2549 / 50 / 51",
    email: "ldetabranch@onekoocoffee.com",
  },
];

const groundedProducts = [
  { image: ground, weight: "2 Kg" },
  { image: ground, weight: "1 Kg" },
  { image: ground, weight: "500 gm" },
  { image: ground, weight: "250 gm" },
];

const roastedProducts = [
  { image: roastedImg, weight: "2 Kg" },
  { image: roastedImg, weight: "1 Kg" },
  { image: roastedImg, weight: "500 gm" },
  { image: roastedImg, weight: "250 gm" },
];

const otherProducts = [
  { name: "Hot Chocolate", image: hot },
  { name: "Cookie", image: dabo },
  { name: "Kolo", image: kolo },
];
function CoffeeShop() {
  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      const element = document.getElementById(location.hash.substring(1));
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);
  return (
    <main
      id="cafes"
      className="flex overflow-hidden flex-col items-center px-10 pt-60 pb-36 bg-white h-auto max-md:px-5 max-md:pb-24"
    >
      <h1 className="flex text-4xl font-bold text-center text-yellow-950">
        <img
          src={LogoBrown}
          alt=""
          className="w-56 h-24 mt-0 md:mt-4 lg:mt-4"
        />
      </h1>
      <h2 className="text-3xl text-yellow-950">Shops</h2>
      <ImageSection src={menu} alt="Coffee cafe illustration" />
      <section className="mt-24 w-full max-w-[1211px] max-md:mt-10 max-md:max-w-full">
        <div className="flex gap-5 max-md:flex-col">
          {shopData.slice(0, 2).map((shop, index) => (
            <ShopCard key={index} {...shop} />
          ))}
        </div>
      </section>
      <section className="mt-24 w-full max-w-[1211px] max-md:mt-10 max-md:max-w-full">
        <div className="flex gap-5 max-md:flex-col">
          {shopData.slice(2).map((shop, index) => (
            <ShopCard key={index} {...shop} />
          ))}
        </div>
      </section>
      <h2 className="mt-11 text-4xl font-bold text-center text-primary max-md:mt-10">
        ONEKOO COFFEE Products
      </h2>
      <ProductSection title="Grounded Products" products={groundedProducts} />
      <ProductSection title="Roasted Products" products={roastedProducts} />
      <section className="mt-32 w-full max-w-[1120px] max-md:mt-10 max-md:max-w-full">
        <h1 className="text-4xl text-primary font-bold py-12">
          Special Products
        </h1>
        <div className="flex gap-5 max-md:flex-col">
          {otherProducts.map((product, index) => (
            <div
              key={index}
              className="flex flex-col items-center p-4 border-secondary rounded-lg shadow-md shadow-secondary"
            >
              {/* Image */}
              <ImageCard {...product} />

              {/* Name */}
              <h2 className="mt-4 text-xl text-primary font-semibold">
                {product.name}
              </h2>

              {/* Button */}
              <button className="mt-4 px-4 py-2 bg-[#094709] hover:bg-[#155715] text-white rounded-lg hover:text-golden">
                <Link onClick={() => window.scrollTo(0, 0)} to={`/pre-shop`}>
                  Buy Now
                </Link>
              </button>
            </div>
          ))}
        </div>
      </section>
    </main>
  );
}

export default CoffeeShop;
