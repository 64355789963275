import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import InvolveNAchiev from "../components/Media/Involve";
// import Blog from "../components/Media/BlogList";
import Award from "../components/Media/Award";
import Article from "../components/Media/Article";
import Download from "../components/Media/Download";
import image1 from "../assets/gallery/gallery-1.png";
import image2 from "../assets/gallery/gallery-2.png";
import image3 from "../assets/gallery/gallery-3.png";
import image4 from "../assets/gallery/gallery-4.png";
import image5 from "../assets/gallery/gallery-5.png";
import image6 from "../assets/gallery/gallery-6.png";
import image7 from "../assets/gallery/gallery-7.png";
import image8 from "../assets/gallery/gallery-8.png";
import image9 from "../assets/gallery/gallery-9.png";
import image10 from "../assets/gallery/gallery-10.png";
import image11 from "../assets/gallery/gallery-11.png";
import image12 from "../assets/gallery/gallery-12.png";
import image13 from "../assets/gallery/gallery-13.png";
import image14 from "../assets/gallery/gallery-14.png";
import image15 from "../assets/gallery/gallery-15.png";
import image16 from "../assets/gallery/gallery-16.png";
import image17 from "../assets/gallery/gallery-17.png";
import image18 from "../assets/gallery/gallery-18.png";
import image19 from "../assets/gallery/gallery-19.png";
import image20 from "../assets/gallery/gallery-20.png";
import image21 from "../assets/gallery/gallery-21.png";
import image22 from "../assets/gallery/gallery-22.png";
import image23 from "../assets/gallery/gallery-23.png";
import image24 from "../assets/gallery/gallery-24.png";
import image25 from "../assets/gallery/gallery-25.png";
import image26 from "../assets/gallery/gallery-26.png";
import image27 from "../assets/gallery/gallery-27.png";
import image28 from "../assets/gallery/gallery-28.png";
import image29 from "../assets/gallery/gallery-29.png";
import image30 from "../assets/gallery/gallery-30.png";

const Media = () => {
  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      const element = document.getElementById(location.hash.substring(1));
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);
  const images = [
    image1,
    image2,
    image3,
    image4,
    image5,
    image6,
    image7,
    image8,
    image9,
    image10,
    image11,
    image12,
    image13,
    image14,
    image15,
    image16,
    image17,
    image18,
    image19,
    image20,
    image21,
    image22,
    image23,
    image24,
    image25,
    image26,
    image27,
    image28,
    image29,
    image30,
  ];
  const [currentIndex, setCurrentIndex] = useState(0);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const prevSlide = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + images.length) % images.length
    );
  };
  // Animation controls for each section
  const controlInvolve = useAnimation();
  const controlBlog = useAnimation();
  const controlAward = useAnimation();
  const controlArticle = useAnimation();
  const controlDownload = useAnimation();

  // Intersection observers for each section
  const [refInvolve, inViewInvolve] = useInView({ triggerOnce: true });
  const [refBlog, inViewBlog] = useInView({ triggerOnce: true });
  const [refAward, inViewAward] = useInView({ triggerOnce: true });
  const [refArticle, inViewArticle] = useInView({ triggerOnce: true });
  const [refDownload, inViewDownload] = useInView({ triggerOnce: true });

  // Trigger animations when in view
  React.useEffect(() => {
    if (inViewInvolve) {
      controlInvolve.start({
        opacity: 1,
        y: 0,
        transition: { duration: 0.8 },
      });
    }
    if (inViewBlog) {
      controlBlog.start({
        opacity: 1,
        y: 0,
        transition: { duration: 0.8 },
      });
    }
    if (inViewAward) {
      controlAward.start({
        opacity: 1,
        y: 0,
        transition: { duration: 0.8 },
      });
    }
    if (inViewArticle) {
      controlArticle.start({
        opacity: 1,
        y: 0,
        transition: { duration: 0.8 },
      });
    }
    if (inViewDownload) {
      controlDownload.start({
        opacity: 1,
        y: 0,
        transition: { duration: 0.8 },
      });
    }
  }, [
    controlInvolve,
    controlBlog,
    controlAward,
    controlArticle,
    controlDownload,
    inViewInvolve,
    inViewBlog,
    inViewAward,
    inViewArticle,
    inViewDownload,
  ]);

  return (
    <div className="overflow-x-hidden md:overflow-x-visible mt-32 lg:overflow-x-visible">
      <motion.div
        ref={refInvolve}
        initial={{ opacity: 0, y: 50 }}
        animate={controlInvolve}
      >
        <InvolveNAchiev />
      </motion.div>

      {/* <motion.div
        ref={refBlog}
        initial={{ opacity: 0, y: 50 }}
        animate={controlBlog}
      >
        <Blog />
      </motion.div> */}

      <motion.div
        ref={refAward}
        initial={{ opacity: 0, y: 50 }}
        animate={controlAward}
      >
        <Award />
      </motion.div>

      <motion.div
        ref={refArticle}
        initial={{ opacity: 0, y: 50 }}
        animate={controlArticle}
      >
        <Article />
      </motion.div>
      <div id="gallery">
        {/* Gallery Section */}
        <div className="gallery-section mb-8">
          <h2 className="text-center text-5xl font-bold my-4">Gallery</h2>
          <div className="carousel-container relative w-full max-w-2xl mx-auto">
            <div className="carousel-slide">
              <img
                src={images[currentIndex]}
                alt={`Slide ${currentIndex}`}
                className="w-full h-[400px] object-cover rounded-lg shadow-lg"
              />
            </div>
            <button
              className="absolute top-1/2 left-0 transform -translate-y-1/2 bg-gray-800 text-white px-2 py-1 rounded-full hover:bg-gray-600"
              onClick={prevSlide}
            >
              &#10094;
            </button>
            <button
              className="absolute top-1/2 right-0 transform -translate-y-1/2 bg-gray-800 text-white px-2 py-1 rounded-full hover:bg-gray-600"
              onClick={nextSlide}
            >
              &#10095;
            </button>
          </div>
          {/* <div className="text-center my-4">
              <button
                className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-700"
                onClick={() => (window.location.href = "/gallery")}
              >
                View Main Gallery
              </button>
            </div> */}
        </div>
      </div>
      <motion.div
        ref={refDownload}
        initial={{ opacity: 0, y: 50 }}
        animate={controlDownload}
      >
        {/* <Download /> */}
      </motion.div>
    </div>
  );
};

export default Media;
