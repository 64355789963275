import React from "react";
// import { Link } from "react-router-dom";
import PremiumPackaging from "./PremiumPackaging/PremiumPackaging";
// import Cafes from "./cafes/CafeLocations";
// import roastChart from "../../assets/images/whatDoWeDo/onekoo_coffee_roast_chart.png";
import WhatWeDoImage from "../../assets/images/whatDoWeDo/whatwedo.png";
import WhysStandsOutImage from "../../assets/images/whatDoWeDo/why we stand out.png";

const WhyOnekooStandsOut = () => (
  <section className="flex flex-col items-center bg-white py-12">
    <h2 className="text-3xl font-bold text-primary mb-6 text-center">Why ONEKOO Coffee Stands Out</h2>
    <div className="mt-6 w-full max-w-6xl px-6 lg:px-12 mx-auto flex flex-col lg:flex-row items-center gap-8">
      <div className="w-full lg:w-1/2">
        <img
          loading="lazy"
          src={WhysStandsOutImage}
          alt="Why ONEKOO Coffee Stands Out"
          className="object-cover w-full  rounded-md"
        />
      </div>
      <div className="w-full lg:w-1/2 text-primary">
        <p className="text-lg leading-relaxed">
          ONEKOO Coffee is more than just a brand; it’s a commitment to quality, ethics, and community. We ethically source our coffee beans directly from Ethiopian farmers, ensuring fair trade and sustainable practices that honor the hard work of our partners. Our coffee is celebrated for its unique aroma and rich flavors, showcasing the finest Ethiopian Arabica beans. But we don't stop there—profits from every sale are reinvested into local communities, supporting education, healthcare, and infrastructure. Choose ONEKOO Coffee and be part of a journey that values exceptional quality and meaningful impact.
        </p>
      </div>
    </div>
  </section>
);

const LookingForward = () => (
  <section className="flex flex-col items-center bg-light-gray py-12">
    <h2 className="text-3xl font-bold text-primary text-center">Looking Forward</h2>
    <div className="mt-6 w-full max-w-6xl px-6 lg:px-12 mx-auto text-primary">
      <p className="text-lg  leading-relaxed">
        At ONEKOO Coffee, we are excited about the future and our plans for growth. We aim to expand our presence both locally and internationally by opening new coffee shops in major cities worldwide and boosting our online sales channels. Innovation is at the heart of what we do, as we continuously explore new ways to enhance our product offerings and operations. This includes developing unique coffee blends, introducing cutting-edge brewing methods, and improving our customer experience. As the coffee industry evolves, we stay adaptable and forward-thinking, committed to staying ahead of market trends and meeting the ever-changing needs of our customers.
      </p>
    </div>
  </section>
);

const WeDo = () => (
  <section className="flex flex-col items-center pt-40 bg-white">
    <h1 className="text-4xl font-bold text-primary">What We Do</h1>
    <p className="mt-2 text-2xl italic text-golden text-center">
      ONEKOO Coffee's Core Offerings
    </p>
    <div className="mt-8 w-full max-w-5xl px-6 lg:px-12 mx-auto">
      <div className="flex flex-col lg:flex-row items-center gap-8">
        <div className="w-full lg:w-3/2">
          <img
            loading="lazy"
            src={WhatWeDoImage}
            alt="Coffee illustration"
            className="object-contain w-full aspect-[0.78] rounded-md"
          />
        </div>
        <div className="w-full lg:w-3/2 bg-white rounded-md shadow-md p-8 text-primary">
          <h2 className="text-2xl font-semibold mb-4 text-center lg:text-left">
            ONEKOO Coffee Shop
          </h2>
          <p className="text-lg leading-relaxed">
            At ONEKOO Coffee, immerse yourself in the rich Ethiopian coffee culture with our premium beverages made from the finest Arabica beans. Enjoy a wide selection of drinks, from espressos and lattes to traditional Ethiopian coffee. Complement your coffee with freshly prepared foods, including pastries, croissants, artisan sandwiches, and healthy salads. Refresh yourself with freshly squeezed juices, milkshakes, and traditional Ethiopian snacks like Kollo and Kita. Our cozy, inviting atmosphere is perfect for social gatherings, business meetings, or a quiet place to work, offering a unique and immersive coffee experience that celebrates Ethiopian heritage.
          </p>
        </div>
      </div>
    </div>
  </section>
);

const WhatWeDo = () => (
  <React.Fragment>
    <div className="pt-28 bg-white">
      <WeDo />
      <PremiumPackaging />
      <WhyOnekooStandsOut />
    <LookingForward />
      {/* <p className="text-start px-6 text-primary md:px-60 lg:px-60 pb-12">
        Our commitment is to deliver the finest coffee experience through both
        our premium packaging and exceptional café services. With each cup of
        Onekoo Coffee, you’re savoring not just a drink, but a tradition of
        quality and dedication.
      </p> */}
    </div>
  </React.Fragment>
);

export default WhatWeDo;
