import React from "react";
import stamer from "../../../assets/buna-setchemer-farm-to-cup.png";

function ImageSection() {
  return (
    <div className="flex flex-col ml-5 w-6/12 max-md:ml-0 max-md:w-full">
      <img
        loading="lazy"
        src={stamer}
        alt="Coffee supply chain illustration"
        className="object-contain grow w-full aspect-[1.19] max-md:mt-2 max-md:max-w-full"
      />
    </div>
  );
}

export default ImageSection;
